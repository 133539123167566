<div class="pre-header">
    <div class="container mobile-container">
        <div class="language-row row">
            <nav>
                <!-- [routerLink]="['translate.currentLang,'private', 'home''] -->
                <a [ngClass]="this.role == 'private' ? 'role-type selected-role' : 'role-type unselected-role'"
                    [routerLink]="" (click)="navigateToPersonal()">{{
                    'header.personal' | translate }}</a>
                <a [ngClass]="this.role == 'business' ? 'role-type selected-role business-border' : 'role-type unselected-role business-border'"
                    [routerLink]="" (click)="navigateToBusiness()">{{ 'header.business' |
                    translate }}</a>
            </nav>
            <div class="language-container">
                <button mat-button (click)="switchLanguage()" class="language-button align-center">
                    <div *ngIf="translate.currentLang == 'en'">
                        <img class="language-icon" src="/assets/icons/menu_header/h_english@3x.png" />
                        <span>{{ 'header.language_english' | translate }}</span>
                    </div>
                    <div *ngIf="translate.currentLang == 'fo'">
                        <img class="language-icon" src="/assets/icons/menu_header/h_faroese@3x.png" />
                        <span>{{ 'header.language_faroese' | translate }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="header">
    <div class="container parent-logo-container">
        <div class="row">
            <div *ngIf="!showSearch" class="col-lg-11 col-md-11 col-10 row mobile-header-row">
                <div class="col-lg-2 col-md-2 col-2 align-self-center logo-column" style="padding-right: 0px">
                    <div class="logo-container">
                        <a routerLink="/">
                            <img src="{{cdnPath}}/images/logo_posta.svg" />
                        </a>
                    </div>
                </div>
                <div *ngFor="let category of categories | slice:0:5; let i = index;" class="header{{i}}"
                    [ngClass]="isMyPostaHeader(category) ? 'col-lg col-md-10 column-content tablet-view categories' : 'col-lg column-content categories'">
                    <div *ngIf="i < 5" class="sibling-container"></div>
                    <button class="category{{i}} header-button" (click)="toggleMenuForCategory(i)">
                        <img *ngIf="isMyPostaHeader(category)" class="user-icon"
                            src="/assets/icons/menu_header/my_posta_user.svg" />
                        <h4 [ngClass]="isMyPostaHeader(category)? 'primary' : ''" class="menu-header-text{{i}}"
                            style="font-weight: 300;">
                            {{category.Title}}</h4>
                    </button>
                </div>
                <div *ngIf="myPostaCategory" class="headerMP col-lg-2 col-md-10 col-10 column-content-my-posta">
                    <div class="sibling-container"></div>
                    <button class="categoryMP header-button" (click)="toggleMenuForCategory(-1)">
                        <img class="user-icon"
                            src="/assets/icons/menu_header/my_posta_user.svg" />
                        <h4 class="menu-header-textMP primary"
                            style="font-weight: 300;">
                            {{myPostaCategory.Title}}</h4>
                    </button>
                </div>
            </div>
            <!-- <div *ngIf="showSearch" class="col-lg-12 col-md-12 col-10 row global-search-row">
                <div class="col-lg-2 col-md-2 col-7 align-self-center no-padding">
                    <div class="logo-container">
                        <img src="/assets/images/PostaLogo@3x.png" />
                    </div>
                </div>
                <div class="col-lg-10 col-md-10 align-center no-padding">
                    <mat-form-field class="full-width global-search-input" floatLabel="never" appearance="outline">
                        <input class="text-input" matInput placeholder="Search..." type="text">
                    </mat-form-field>
                    <div class="global-search-button-container">
                        <button class="search-button" (click)="search()">
                            <img class="search-icon" src="/assets/icons/menu_header/m_search@3x.png" />
                        </button>
                    </div>
                    <div class="global-search-button-container">
                        <button class="search-button" (click)="closeSearch()">
                            <img class="search-icon" src="/assets/icons/arrows/close@3x.png" />
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="!showSearch" class="search-container col-lg-1 col-md-1 col-1 row">
                <button class="search-button" (click)="search()">
                    <img class="search-icon" src="/assets/icons/menu_header/m_search@3x.png" />
                </button>
            </div> -->
            <div *ngIf="!showTabletView" class="header-options-container col-md-1 col-1 row">
                <button class="header-options-btn" (click)="showTabletView=true;">
                    <img class="hamburger-menu-icon" src="/assets/icons/menu_header/hamburger_menu@3x.png" />
                </button>
            </div>
            <div *ngIf="showTabletView" class="header-options-container col-md-1 col-1 row">
                <button class="header-options-btn" (click)="showTabletView=false;">
                    <img class="hamburger-menu-icon" src="/assets/icons/arrows/close@3x.png" />
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showTabletView" class="menu-tablet-container">
    <div *ngFor="let category of categories; let i = index;">
        <div *ngIf="!isMyPostaHeader(category)" class="tablet-header-button-container" (click)="toggleMenuForCategory(i)">
            <div class="row full-width" style="padding-left: 35px;">
                <button class="category{{i}} tablet-header-button">
                    <img class="drop-down-arrow" src="/assets/icons/arrows/homepage_tablet_arrow.png" onclick="toggleMenuForCategory(i)" />
                    <h4 class="menu-header-text{{i}} tablet-header-header-title" style="font-weight: 300;">
                        {{category.Title}}</h4>
                </button>
            </div>
            <!-- Refactor this below -->
            <div *ngIf="!!subCategories && showMenu1 && 1 == i+1">
                <div *ngFor="let subCategory of subCategories | keyvalue" class="full-width">
                    <h4 *ngIf="!!subCategory.key" class="bold subcategory-title">{{subCategory.key}}</h4>
                    <div *ngFor="let page of subCategory.value" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon != ''">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <img src="{{cdnPath}}{{page.MenuIcon}}" width="40px" height="40px"
                                    style="margin-right: 10px;" />
                                    <div>
                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                    </div>
                            </button>
                        </div>
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                    <div class="menu-button-text">
                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                    </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!!subCategories && showMenu2 && 2 == i+1">
                <div *ngFor="let subCategory of subCategories | keyvalue" class="full-width">
                    <h4 *ngIf="!!subCategory.key" class="bold subcategory-title">{{subCategory.key}}</h4>
                    <div *ngFor="let page of subCategory.value" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon != ''">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <img src="{{cdnPath}}{{page.MenuIcon}}" width="40px" height="40px"
                                    style="margin-right: 10px;" />
                                    <div class="menu-button-text">
                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                    </div>
                            </button>
                        </div>
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <div class="menu-button-text">
                                    <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                    <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!!subCategories && showMenu3 && 3 == i+1">
                <div *ngFor="let subCategory of subCategories | keyvalue" class="full-width">
                    <h4 *ngIf="!!subCategory.key" class="bold subcategory-title">{{subCategory.key}}</h4>
                    <div *ngFor="let page of subCategory.value" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon != ''">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <img src="{{cdnPath}}{{page.MenuIcon}}" width="40px" height="40px"
                                    style="margin-right: 10px;" />
                                    <div class="menu-button-text">
                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                    </div>
                            </button>
                        </div>
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <div class="menu-button-text">
                                    <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                    <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!!subCategories && showMenu4 && 4 == i+1">
                <div *ngFor="let subCategory of subCategories | keyvalue" class="full-width">
                    <h4 *ngIf="!!subCategory.key" class="bold subcategory-title">{{subCategory.key}}</h4>
                    <div *ngFor="let page of subCategory.value" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon != ''">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <img src="{{cdnPath}}{{page.MenuIcon}}" width="40px" height="40px"
                                    style="margin-right: 10px;" />
                                    <div class="menu-button-text">
                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                    </div>
                            </button>
                        </div>
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <div class="menu-button-text">
                                    <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                    <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div *ngFor="let page of pages" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <div class="menu-button-text">
                                    <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                    <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!!subCategories && showMenu5 && 5 == i+1">
                <div *ngFor="let subCategory of subCategories | keyvalue" class="full-width">
                    <h4 *ngIf="!!subCategory.key" class="bold subcategory-title">{{subCategory.key}}</h4>
                    <div *ngFor="let page of subCategory.value" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon != ''">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <img src="{{cdnPath}}{{page.MenuIcon}}" width="40px" height="40px"
                                    style="margin-right: 10px;" />
                                    <div class="menu-button-text">
                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                    </div>
                            </button>
                        </div>
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <div class="menu-button-text">
                                    <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                    <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div *ngFor="let page of pages" class="row" style="padding-left: 35px;">
                        <div *ngIf="page.MenuIcon == ''" style="padding-left: 50px">
                            <button class="menu-button" [routerLink]="[page.Url]">
                                <div class="menu-button-text">
                                    <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                    <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showMenu1">
    <div *ngIf="subCategories.size > 1" class="parent-drop-down-container">
        <div class="container">
            <div class="row">
                <div *ngFor="let subCategory of subCategories | keyvalue"
                    class="col-lg-6 full-width subcategory-container">
                    <h4 class="header-grey bold subcategory-title">{{subCategory.key}}</h4>
                    <div class="row">
                        <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon != ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                            style="margin-right: 10px;" />
                                            <div class="menu-button-text">
                                                <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                            </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon == ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="subCategories.size == 1" class="container drop-down-container">
        <div *ngFor="let subCategory of subCategories | keyvalue">
            <div *ngIf="hasItemsWithIcons(subCategory.value)">
                <div class="row">

                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-7 full-width single-category-container">
                                <div class="row">
                                    <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                                        <div *ngFor="let page of subCategory.value" class="row">
                                            <div *ngIf="page.MenuIcon != ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                                        style="margin-right: 10px;" />
                                                        <div class="menu-button-text">
                                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                        </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 full-width">
                                        <div *ngFor="let page of pages" class="row">
                                            <div *ngIf="page.MenuIcon == ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <div class="menu-button-text">
                                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasItemsWithIcons(subCategory.value)">
                <div class="row">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-3 full-width single-category-container">
                                <div *ngFor="let page of pages" class="row single-category-menu-option">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showMenu2">
    <div *ngIf="subCategories.size > 1" class="parent-drop-down-container">
        <div class="container">
            <div class="row">
                <div *ngFor="let subCategory of subCategories | keyvalue"
                    class="col-lg-6 full-width subcategory-container">
                    <h4 class="header-grey bold subcategory-title">{{subCategory.key}}</h4>
                    <div class="row">
                        <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon != ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                            style="margin-right: 10px;" />
                                            <div class="menu-button-text">
                                                <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                            </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon == ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="subCategories.size == 1" class="container drop-down-container">
        <div *ngFor="let subCategory of subCategories | keyvalue">
            <div *ngIf="hasItemsWithIcons(subCategory.value)">
                <div class="row">

                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-7 full-width single-category-container">
                                <div class="row">
                                    <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                                        <div *ngFor="let page of subCategory.value" class="row">
                                            <div *ngIf="page.MenuIcon != ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                                        style="margin-right: 10px;" />
                                                        <div class="menu-button-text">
                                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                        </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 full-width">
                                        <div *ngFor="let page of pages" class="row">
                                            <div *ngIf="page.MenuIcon == ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <div class="menu-button-text">
                                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasItemsWithIcons(subCategory.value)">
                <div class="row">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-4"></div>
                            <div class="col-lg-3 full-width single-category-container">
                                <div *ngFor="let page of pages" class="row single-category-menu-option">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showMenu3">
    <div *ngIf="subCategories.size > 1" class="parent-drop-down-container">
        <div class="container">
            <div class="row">
                <div *ngFor="let subCategory of subCategories | keyvalue"
                    class="col-lg-6 full-width subcategory-container">
                    <h4 class="header-grey bold subcategory-title">{{subCategory.key}}</h4>
                    <div class="row">
                        <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon != ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                            style="margin-right: 10px;" />
                                            <div class="menu-button-text">
                                                <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                            </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon == ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="subCategories.size == 1" class="container drop-down-container">
        <div *ngFor="let subCategory of subCategories | keyvalue">
            <div *ngIf="hasItemsWithIcons(subCategory.value)">
                <div class="row">

                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-7 full-width single-category-container">
                                <div class="row">
                                    <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                                        <div *ngFor="let page of subCategory.value" class="row">
                                            <div *ngIf="page.MenuIcon != ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                                        style="margin-right: 10px;" />
                                                        <div class="menu-button-text">
                                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                        </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 full-width">
                                        <div *ngFor="let page of pages" class="row">
                                            <div *ngIf="page.MenuIcon == ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <div class="menu-button-text">
                                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasItemsWithIcons(subCategory.value)">
                <div class="row">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-6"></div>
                            <div class="col-lg-3 full-width single-category-container">
                                <div *ngFor="let page of pages" class="row single-category-menu-option">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showMenu4">
    <div *ngIf="subCategories.size > 1" class="parent-drop-down-container">
        <div class="container">
            <div class="row">
                <div *ngFor="let subCategory of subCategories | keyvalue"
                    class="col-lg-6 full-width subcategory-container">
                    <h4 class="header-grey bold subcategory-title">{{subCategory.key}}</h4>
                    <div class="row">
                        <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon != ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                            style="margin-right: 10px;" />
                                            <div class="menu-button-text">
                                                <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                            </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon == ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="subCategories.size == 1" class="container drop-down-container">
        <div *ngFor="let subCategory of subCategories | keyvalue">
            <div *ngIf="hasItemsWithIcons(subCategory.value)">
                <div class="row">

                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-5"></div>
                            <div class="col-lg-7 full-width single-category-container">
                                <div class="row">
                                    <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                                        <div *ngFor="let page of subCategory.value" class="row">
                                            <div *ngIf="page.MenuIcon != ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">

                                                    <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                                        style="margin-right: 10px;" />
                                                        <div class="menu-button-text">
                                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                        </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 full-width">
                                        <div *ngFor="let page of pages" class="row">
                                            <div *ngIf="page.MenuIcon == ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <div class="menu-button-text">
                                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasItemsWithIcons(subCategory.value)">
                <div class="row">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-8"></div>
                            <div class="col-lg-3 full-width single-category-container">
                                <div *ngFor="let page of pages" class="row single-category-menu-option">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showMenu5">
    <div *ngIf="subCategories.size > 1" class="parent-drop-down-container">
        <div class="container">
            <div class="row">
                <div *ngFor="let subCategory of subCategories | keyvalue"
                    class="col-lg-6 full-width subcategory-container">
                    <h4 class="header-grey bold subcategory-title">{{subCategory.key}}</h4>
                    <div class="row">
                        <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon != ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                            style="margin-right: 10px;" />
                                            <div class="menu-button-text">
                                                <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                            </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 full-width">
                            <div *ngFor="let page of subCategory.value" class="row">
                                <div *ngIf="page.MenuIcon == ''">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="subCategories.size == 1" class="container drop-down-container">
        <div *ngFor="let subCategory of subCategories | keyvalue">
            <div *ngIf="hasItemsWithIcons(subCategory.value)">
                <div class="row">

                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-5"></div>
                            <div class="col-lg-7 full-width single-category-container">
                                <div class="row">
                                    <div *ngIf="hasItemsWithIcons(subCategory.value)" class="col-lg-6 full-width">
                                        <div *ngFor="let page of subCategory.value" class="row">
                                            <div *ngIf="page.MenuIcon != ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">

                                                    <img src="{{cdnPath}}{{page.MenuIcon}}" width="25px" height="25px"
                                                        style="margin-right: 10px;" />
                                                        <div class="menu-button-text">
                                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                        </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 full-width">
                                        <div *ngFor="let page of pages" class="row">
                                            <div *ngIf="page.MenuIcon == ''">
                                                <button class="menu-button" [routerLink]="[page.Url]">
                                                    <div class="menu-button-text">
                                                        <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                                        <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasItemsWithIcons(subCategory.value)">
                <div class="row">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-8"></div>
                            <div class="col-lg-3 full-width single-category-container">
                                <div *ngFor="let page of pages" class="row single-category-menu-option">
                                    <button class="menu-button" [routerLink]="[page.Url]">
                                        <div class="menu-button-text">
                                            <h4 class="menu-item-title primary">{{page.Title}}</h4>
                                            <span *ngIf="!!page.MenuDescription" class="menu-button-description">{{page.MenuDescription}}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showMyPostaMenu" class="container drop-down-container-my-posta">
    <div class="row">
        <div class="col-lg-9 col-md-7 col-0"></div>
        <div class="col-lg-3 col-md-5 col-12 full-width">
            <div *ngFor="let page of pages" class="row menu-option-container">
                <button
                    *ngIf="page.Url != '/en/private/my-posta/new-order/' && page.Url != '/fo/private/my-posta/new-order/'"
                    class="menu-button menu-button-my-posta" [routerLink]="[page.Url]">
                    <h4 class="menu-item-title">{{page.Title}}</h4>
                </button>
                <button
                    *ngIf="page.Url == '/en/private/my-posta/new-order/' || page.Url == '/fo/private/my-posta/new-order/'"
                    class="new-order-button" [routerLink]="[page.Url]">
                    <h4 class="menu-item-title">{{page.Title}}</h4>
                </button>
                <div *ngFor="let subPage of page.Pages" class="row menu-option-container" style="display: block; width: 100%; margin: 0px;">
                    <button 
                        *ngIf="subPage.Url != '/en/private/my-posta/new-order/' && subPage.Url != '/fo/private/my-posta/new-order/'"
                        class="menu-button-child" [routerLink]="[subPage.Url]">
                        <h4 class="menu-item-title">{{subPage.Title}}</h4>
                    </button>
                </div>
            </div>
            <div *ngIf="isLoggedIn" class="row menu-option-container">
                <button class="menu-button menu-button-my-posta" (click)="logout()">
                    <h4 class="menu-item-title">{{ 'header.logout' | translate }}</h4>
                </button>
            </div>
            <div *ngIf="!isLoggedIn" class="row login-menu-option-container">
                <h4 *ngIf="role == 'private'" class="sub-title-login">{{ 'header.personal_login' | translate }}</h4>
                <h4 *ngIf="role == 'business'" class="sub-title-login">{{ 'header.business_login' | translate }}</h4>
                <!-- <button *ngIf="role == 'private'" class="samleikin-button" (click)="navigateToSamleikin()"><img
                        class="samleikin-img" src="/assets/icons/login/samleikin_logo.png" /></button> -->
                <!-- <h4 *ngIf="role == 'private'" class="sub-title-login">{{ 'header.no_samleikin' | translate }}</h4> -->
                <mat-form-field class="email-input full-width align-form-field" [formGroup]="passwordForm"
                    floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{ 'header.enter_email' | translate }}" id="email" name="email"
                        formControlName="email" required>
                    <mat-error *ngIf="hasErrors('email')">
                        <app-field-error-message errorMessage="{{getError('email')}}"></app-field-error-message>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width align-form-field" [formGroup]="passwordForm" floatLabel="never"
                    appearance="outline">
                    <input type="password" matInput placeholder="{{ 'header.enter_password' | translate }}"
                        (keyup.enter)="login()" id="password" name="password" formControlName="password" required>
                    <mat-error *ngIf="hasErrors('password')">
                        <app-field-error-message errorMessage="{{getError('password')}}"></app-field-error-message>
                    </mat-error>
                </mat-form-field>
                <button class="login-button primary-button full-width" type="submit" (click)="login()">
                    {{ 'header.login' | translate }}
                </button>
                <a routerLink="" (click)="navigateToForgottenPassword()" class="forgotten-password">{{
                    'header.forgotten_password' | translate }}</a>
            </div>
            <div *ngIf="!isLoggedIn" class="row menu-option-container">
                <button class="secondary-login-button" (click)="navigateToRegister()">
                    <h4 style="font-size: 15px;"><i>{{ 'header.new_user_register' | translate }}</i></h4>
                </button>
            </div>
            <div *ngIf="!isLoggedIn" class="row menu-option-container">
                <button class="secondary-login-button" (click)="navigateToLogin()">
                    <h4 *ngIf="role == 'private'" style="font-size: 15px;"><i>{{ 'header.personal_user_login_redirect' |
                        translate }}</i></h4>
                    <h4 *ngIf="role == 'business'" style="font-size: 15px;"><i>{{ 'header.business_user_login_redirect' |
                        translate }}</i></h4>
                </button>
            </div>
        </div>
    </div>
</div>