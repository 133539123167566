import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../account/services/authentication.service';
import { RoleService } from '../../account/services/role.service';
import { StaticContentService } from '../../shared/services/static-content.service';
import { BrochureDialogComponent } from '../brochure-dialog/brochure-dialog.component';
import { GeneratedCodeDialogComponent } from '../generated-code-dialog/generated-code-dialog/generated-code-dialog.component';
import { NewShoppingListDialogComponent } from '../new-shopping-list-dialog/new-shopping-list-dialog.component';
import { BrochureService } from '../services/brochure-service';
import { ProductService } from '../services/product.service';
import { ShoppingListService } from '../services/shopping-list-service';
import { ShoppingList } from '../models/ShoppingList';
import { ShoppingListsLoggedOut } from '../models/ShoppingListLoggedOut';
import { Location } from '@angular/common';

@Component({
  selector: 'app-offers-products',
  templateUrl: './offers-products.component.html',
  styleUrls: ['./offers-products.component.scss']
})
export class OffersProductsComponent implements OnInit {

  offerProductForm: FormGroup;
  public isBrochuresSelected: boolean = true;
  public brochures: Array<any>;
  public searchText: string;
  public products: Array<any>;
  public categories: Array<any>;
  public loading: boolean = true;
  public loadingBrochure: boolean = false;
  public showPDF: boolean = false;
  public pdfContent: any;
  public shoppingLists: Array<any>;
  public activeList: any;
  public activeListLoggedOut: ShoppingList;
  public lastResultsLength: number;
  public hideViewMore: boolean = false;
  private interval: any;
  public image: string;
  public selectedBrochureIndex;
  public loggedOutShoppingLists: ShoppingListsLoggedOut;

  //Default filter values
  public productFilter = {
    "LanguageCode": `${this.translateService.currentLang}`,
    "SkipCount": 0,
    "TakeCount": 16,
    "FilterParameters": {
      "Keyword": "",
      "Name": "",
      "Categories": "",
      "Departments": "",
      "SubDepartments": "",
      "Manufacturers": "",
      "Publishers": "",
      "BrochureId": null,
      "MinPrice": null,
      "MaxPrice": null,
      "IsIncludeImage": true
    }
  }

  public brochurefilter = {
    "LanguageCode": `${this.translateService.currentLang}`,
    "SkipCount": 0,
    "TakeCount": 16,
    "FilterParameters": {
      "Keyword": "",
      "Name": "",
      "Categories": "",
      "Departments": "",
      "SubDepartments": "",
      "Manufacturers": "",
      "Publishers": "",
      "BrochureId": null,
      "MinPrice": null,
      "MaxPrice": null,
      "IsIncludeImage": true
    }
  }

  constructor(private brochureService: BrochureService,
    private productService: ProductService,
    private translateService: TranslateService,
    private roleService: RoleService,
    private shoppingListService: ShoppingListService,
    private userService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private staticContentService: StaticContentService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _location: Location) { }

  ngOnInit(): void {
    // this.interval = setInterval(() => {
    //   var staticContent = this.staticContentService.staticContent;
    //   if (!!staticContent) {
    //     this.staticContentService.loadAllCssJs();
    //     this.image = this.staticContentService.getImageForPsModule();
    //     clearInterval(this.interval);
    //   }
    // }, 200);

    this.translateService.get("bera.brochures_products.banner_image").subscribe(imgUrl => {
      if(imgUrl == "bera.brochures_products.banner_image")
      {
        //No Image Set
        this.image = null;
      }
      else{
        this.image = imgUrl;
      }
    })

    this.offerProductForm = this._formBuilder.group({
      category: [''],
      activeList: [''],
      activeListLoggedOut: [''],
    });


    var mode = this._route.snapshot.paramMap.get('mode');
    if(mode == "products"){
      this.isBrochuresSelected = false;
    }

    this.setLoggedOutLists();

    // translate service isn't setting the correct language on init
    setTimeout(()=>{                          
      this.getAllBrochuresOrProducts();
      this.getAllShoppingLists();
  }, 2000);

  }

  public filterProductsByName() {
    if (this.isBrochuresSelected) {
      if (!this.searchText || this.searchText == '') {
        this.productFilter.FilterParameters.Keyword = '';
        this.getBrochuresByFilter();
        return;
      }

      const url = this.router.createUrlTree(
        [
          this._route.snapshot.paramMap.get('lang'),
          this._route.snapshot.paramMap.get('role'),
          "shopping-services",
          "offers-products", 
          "products"
        ], {}).toString();
      this._location.go(url);
      this.isBrochuresSelected = false;
      this.hideViewMore = false;
      this.products = [];
      this.brochures = [];
      this.categories = [];
      this.offerProductForm.get("category").reset();
      this.loading = true;
      this.productFilter.FilterParameters.Keyword = this.searchText;
      this.getProductsByFilter();

      // this.productFilter.FilterParameters.Keyword = this.searchText;
      // this.loading = true;
      // this.productService.getAll(this.productFilter).subscribe(x => {
      //   this.products = x.productList;
      //   this.categories = x.applicableCategories;
      //   const brochureIds = this.products?.map(x => x.brochureId);
      //   if (!!brochureIds && brochureIds.length > 0) {
      //     this.brochureService.getByFilter(this.brochurefilter).subscribe(y => {
      //       y.brochureList.forEach(element => {
      //         element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
      //         //Set image width and height
      //         let img = new Image();

      //         img.src = element.imageUrl;
      //         img.onload = () => {
      //           //Max Height = 330px
      //           //Max Width = 235px
      //           var ratio = Math.min(235 / img.naturalWidth, 330 / img.naturalHeight);
      //           element.width = img.naturalWidth * ratio;
      //           element.height = img.naturalHeight * ratio;
      //         }
      //       });
      //       this.brochures = y.brochureList.filter(z => brochureIds.includes(z.brochureId));
      //       this.loading = false;
      //     });
      //   }
      //   else {
      //     this.brochures = [];
      //     this.loading = false;
      //   }
      // });
    }
    else {
      if (!this.searchText || this.searchText == '') {
        this.productFilter.FilterParameters.Keyword = '';
        this.getProductsByFilter();
        return;
      }

      this.productFilter.FilterParameters.Keyword = this.searchText;
      this.getProductsByFilter();
    }
  }

  public getAllBrochuresOrProducts(): void {
    if (this.isBrochuresSelected) {
      this.resetBrochureFilter();
      this.getBrochuresByFilter(true);
    }
    else {
      this.resetProductFilter();
      this.getProductsByFilter(true);
    }
  }

  public updateByCategories(event: any): void {
    if (this.isBrochuresSelected) {
      this.brochurefilter.FilterParameters.Categories = String(event.value.categoryId);
      this.filterProductsByName();
    }
    else {
      this.productFilter.FilterParameters.Categories = String(event.value.categoryId);
      this.filterProductsByName();
    }
  }

  public navigateToViewList(): void {
    var id = this.isLoggedIn() ? this.activeList.shoppingListId : null
    var loggedOutList = this.isLoggedIn() ? null : this.activeListLoggedOut;
    if (!id && !loggedOutList)
    {
      return;
    }

    this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/shopping-services/view-shopping-list/${id}`, { list: JSON.stringify(loggedOutList) }]);
  }

  public navigateToEditLists(): void {
    this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/shopping-services/view-all-shopping-lists/`]);
  }

  private getBrochuresByFilter(initialLoad: boolean = false): void {
    this.loading = true;
    this.brochureService.getByFilter(this.brochurefilter).subscribe(x => {
      if ((initialLoad && x.brochureList.length < 16) || (x.brochureList.length % 16 !== 0)) {
        this.hideViewMore = true;
      }
      if (!this.categories || this.categories.length == 0)
      {
        this.categories = x.applicableCategories;
      }
      x.brochureList.forEach(element => {
        element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
        //Set image width and height
        let img = new Image();

        img.src = element.imageUrl;
        img.onload = () => {
          var ratio = Math.min(235 / img.naturalWidth, 330 / img.naturalHeight);
          element.width = img.naturalWidth * ratio;
          element.height = img.naturalHeight * ratio;
        }
      });
      this.brochures = x.brochureList;
      // if (this.lastResultsLength === this.brochures.length || this.brochures.length === 0) {
      //   this.hideViewMore = true;
      // }
      this.loading = false;
    });
  }

  public onImageLoad(obj: any): void {
    //Set image width and height
    let img = new Image();

    img.src = obj.imageUrl;
    var ratio = Math.min(235 / img.naturalWidth, 330 / img.naturalHeight);
    obj.width = img.naturalWidth * ratio;
    obj.height = img.naturalHeight * ratio;
  }

  private getProductsByFilter(initialLoad: boolean = false, isReset: boolean = false): void {
    this.loading = true;
    this.productService.getAll(this.productFilter).subscribe(x => {
      if (!this.categories || this.categories.length == 0)
      {
        this.categories = x.applicableCategories;
      }
      if ((initialLoad && x.productList.length < 16) || (x.productList.length % 16 !== 0)) {
        this.hideViewMore = true;
      }
      x.productList.forEach(element => {
        element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
        //Set image width and height
        let img = new Image();

        img.src = element.imageUrl;
        img.onload = () => {
          var ratio = Math.min(235 / img.naturalWidth, 207 / img.naturalHeight);
          element.width = img.naturalWidth * ratio;
          element.height = img.naturalHeight * ratio;
        }
      });
      this.products = x.productList;
      if (!isReset && (this.lastResultsLength === this.products.length || this.products.length === 0)) {
        this.hideViewMore = true;
      }
      this.loading = false;
    });
  }

  public reset(brochureId?: Number): void {
    this.hideViewMore = false;
    this.products = [];
    this.brochures = [];
    this.categories = [];
    this.resetProductFilter();
    this.resetBrochureFilter();
    this.searchText = '';
    this.offerProductForm.get("category").reset();
    this.loading = true;
    if (this.isBrochuresSelected) {
      this.getBrochuresByFilter();
    }
    else {
      if (brochureId) {
        this.productFilter.FilterParameters.BrochureId = String(brochureId);
      }
      this.getProductsByFilter(false, true);
    }
  }

  public viewBrochure(brochure: any, index: any): void {
    if(brochure.brochureUrl){
      if(brochure.brochureUrl.startsWith("http"))
        window.open(brochure.brochureUrl, '_blank');
      else
        window.open(`http://${brochure.brochureUrl}`, '_blank');
      return;
    }
    var brochureGuid = brochure.thumbPath;

    this.selectedBrochureIndex = index;
    this.loadingBrochure = true;
    const latterPart = brochureGuid.split('Brochures/')[1];
    const formerPart = latterPart.split('/')[0];
    this.brochureService.getPDF(formerPart).subscribe(x => {
      this.pdfContent = this.base64ToArrayBuffer(x.content);
      this.showPDF = true;
      const dialogRef = this.dialog.open(BrochureDialogComponent, {
        height: '95vh',
        width: '95vw',
        maxWidth: '95vw',
        data: {
          pdfSource: this.pdfContent,
        }
      });
      this.loadingBrochure = false;

      dialogRef.afterClosed().subscribe(result => {
      });
    });
  }

  public viewBrochureByProduct(brochureId: string, pageIndex: number, index: any): void {
    this.selectedBrochureIndex = index;
    this.loadingBrochure = true;
    const filterById = {
      "LanguageCode": `${this.translateService.currentLang}`,
      "FilterParameters": {
        "Keyword": "",
        "Name": "",
        "Categories": "",
        "Departments": "",
        "SubDepartments": "",
        "Manufacturers": "",
        "Publishers": "",
        "BrochureId": `${brochureId}`,
        "MinPrice": null,
        "MaxPrice": null,
        "IsIncludeImage": true
      }
    }
    this.brochureService.getByFilter(filterById).subscribe(x => {
      if (x && x.brochureList.length > 0) {
        const brochure = x.brochureList[0];
        const brochureThumbPath = brochure.thumbPath;
        const latterPart = brochureThumbPath.split('Brochures/')[1];
        const formerPart = latterPart.split('/')[0];
        this.brochureService.getPDF(formerPart).subscribe(x => {
          this.pdfContent = this.base64ToArrayBuffer(x.content);
          this.showPDF = true;
          const dialogRef = this.dialog.open(BrochureDialogComponent, {
            height: '95vh',
            width: '95vw',
            maxWidth: '95vw',
            data: {
              pdfSource: this.pdfContent,
              pageIndex: pageIndex
            }
          });

          dialogRef.afterClosed().subscribe(result => {

          });
          this.loadingBrochure = false;
        });
      }
      else {
        this.loadingBrochure = false;
      }
    });
  }

  public onBrochureProductChange(setBrochureView: boolean, brochureId?: number): void {
    
    const url = this.router.createUrlTree(
      [
        this._route.snapshot.paramMap.get('lang'),
        this._route.snapshot.paramMap.get('role'),
        "shopping-services",
        "offers-products", 
        setBrochureView ? "brochures" : "products"
      ], {}).toString();
    this._location.go(url);
    if (this.isBrochuresSelected === setBrochureView) {
      this.reset();
    }

    this.isBrochuresSelected = setBrochureView;
    this.reset(brochureId);
  }

  public createShareLink(): void {
    var stringBuilder = "";
    if (this.activeList && this.activeList.productItemNames.length > 0) {
      stringBuilder = stringBuilder.concat(`${this.activeList.shoppingListName}:\n`);
      this.activeList.productItemNames.forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
    }
    else if (this.activeListLoggedOut && this.activeListLoggedOut.products)
    {
      stringBuilder = stringBuilder.concat(`${this.activeListLoggedOut.name}:\n`);
      this.activeListLoggedOut.products.map(z => z.productName).forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
    }

    if (this.isLoggedIn()) {
      const shareFilter = {
        "LanguageCode": `${this.translateService.currentLang}`,
        "Role": `${this.roleService.role()}`,
        "ShoppingListId": this.activeList.shoppingListId
      }

      this.shoppingListService.shareList(shareFilter).subscribe(x => {
        const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
          height: '600px',
          width: '730px',
          data: {
            link: x,
            plainText: stringBuilder
          }
        });
      });
    }
    else if (this.activeListLoggedOut && stringBuilder !== "")
      {
        const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
          height: '600px',
          width: '730px',
          data: {
            link: null,
            plainText: stringBuilder
          }
        })
      }
  }

  public updateActiveList(event: any): void {
    if (this.isLoggedIn()) {
      this.getProductsForList(event.value);
    }
    else {
      this.activeListLoggedOut = event.value;

      var shoppingLists = localStorage.getItem("shoppingLists");
      if (!!shoppingLists) {
        var lists: ShoppingListsLoggedOut = JSON.parse(shoppingLists);
        lists.shoppingLists.forEach(x => x.isActive = false);
        var indexToUpdate = lists.shoppingLists.findIndex(x => x.name == this.activeListLoggedOut.name);
        this.activeListLoggedOut.isActive = true;

        lists.shoppingLists[indexToUpdate] = this.activeListLoggedOut;
        localStorage.setItem("shoppingLists", JSON.stringify(lists));
      }

    }
  }

  public addToActiveList(product: any): void {
    if (!this.activeList && !this.activeListLoggedOut) {
      this.addNewShoppingList(product);
      return;
    }
    if (this.isLoggedIn()) {

      const addToListBody = {
        "ShoppingListId": this.activeList.shoppingListId,
        "ProductId": product.productId,
        "ItemName": product.name
      }
      this.shoppingListService.addToShoppingList(addToListBody).subscribe(x => {
        this.getAllShoppingLists();
      });
    }
    else {
      if (!this.activeListLoggedOut.products) {
        this.activeListLoggedOut.products = [];
      }
      this.activeListLoggedOut.products.push({ productId: product.productId, productName: product.name, retailerName: product.retailerName, price: product.price, offerUntilDate: product.offerUntilDate });
      var shoppingLists = localStorage.getItem("shoppingLists");
      if (!!shoppingLists) {
        var lists: ShoppingListsLoggedOut = JSON.parse(shoppingLists);
        var indexToUpdate = lists.shoppingLists.findIndex(x => x.name == this.activeListLoggedOut.name);
        lists.shoppingLists[indexToUpdate] = this.activeListLoggedOut;
        localStorage.setItem("shoppingLists", JSON.stringify(lists));
      }
    }
  }

  public removeFromActiveList(product: any): void {
    const addToListBody = {
      "ShoppingListId": this.activeList.shoppingListId,
      "ProductId": product.productId,
      "ItemName": product.name
    }
    this.shoppingListService.addToShoppingList(addToListBody).subscribe(x => {
      this.getAllShoppingLists();
    });
  }

  public addNewShoppingList(product: any = null): any {
    const shoppingDialogref = this.dialog.open(NewShoppingListDialogComponent, {
      height: '170px',
      width: '580px'
    });

    shoppingDialogref.afterClosed().subscribe(result => {
      if (result) {
        if (this.isLoggedIn()) {
          this.shoppingListService.getAll().subscribe(x => {
            this.shoppingLists = x;
            const newlyCreatedList = x.find(y => y.shoppingListId == result);
            this.getProductsForList(newlyCreatedList);
          })
        }
        else {
          this.activeListLoggedOut = result;
          //this.setLoggedOutLists();
          // if (!this.loggedOutShoppingLists?.shoppingLists)
          // {
          //   this.loggedOutShoppingLists.shoppingLists = [];
          // }
          var shoppingLists = localStorage.getItem("shoppingLists");
          if (!!shoppingLists) {
            var lists: ShoppingListsLoggedOut = JSON.parse(shoppingLists);
            this.loggedOutShoppingLists = lists;
            
            var list = this.loggedOutShoppingLists.shoppingLists.find(x => x.name == this.activeListLoggedOut.name);
            this.offerProductForm.get('activeListLoggedOut').setValue(list);
          }

        }
        this.addToActiveList(product);
      }
    });
  }


  private setLoggedOutLists() {
    var shoppingLists = localStorage.getItem("shoppingLists");
    if (!!shoppingLists) {
      var lists: ShoppingListsLoggedOut = JSON.parse(shoppingLists);
      this.loggedOutShoppingLists = lists;
      
      var activeLoggedOutList = this.loggedOutShoppingLists.shoppingLists?.find(x => x.isActive);
      this.activeListLoggedOut = activeLoggedOutList;
      this.offerProductForm.get('activeListLoggedOut').setValue(activeLoggedOutList);
    }
  }

  public setProductImgWidth(product: any) {
    let img = new Image();

    img.src = product.imageUrl;

    var ratio = Math.min(235 / img.naturalWidth, 207 / img.naturalHeight);
    return (img.naturalWidth * ratio);
  }

  public setProductImgHeight(product: any) {
    let img = new Image();

    img.src = product.imageUrl;
    var ratio = Math.min(235 / img.naturalWidth, 207 / img.naturalHeight);
    return (img.height * ratio);
  }

  private resetBrochureFilter(): void {
    this.brochurefilter = {
      "LanguageCode": `${this.translateService.currentLang}`,
      "SkipCount": 0,
      "TakeCount": 16,
      "FilterParameters": {
        "Keyword": "",
        "Name": "",
        "Categories": "",
        "Departments": "",
        "SubDepartments": "",
        "Manufacturers": "",
        "Publishers": "",
        "BrochureId": null,
        "MinPrice": null,
        "MaxPrice": null,
        "IsIncludeImage": true
      }
    }
  }

  private resetProductFilter(): void {
    this.productFilter = {
      "LanguageCode": `${this.translateService.currentLang}`,
      "SkipCount": 0,
      "TakeCount": 16,
      "FilterParameters": {
        "Keyword": "",
        "Name": "",
        "Categories": "",
        "Departments": "",
        "SubDepartments": "",
        "Manufacturers": "",
        "Publishers": "",
        "BrochureId": null,
        "MinPrice": null,
        "MaxPrice": null,
        "IsIncludeImage": true
      }
    }
  }

  private base64ToArrayBuffer(base64): Uint8Array {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }

  private getAllShoppingLists(): void {
    this.shoppingListService.getAll().subscribe(x => {
      this.shoppingLists = x;
      this.activeList = this.shoppingLists.find(y => y.isActive);
      this.getProductsForList(this.activeList);
    });
  }

  private getProductsForList(list: any): void {
    this.shoppingListService.setActiveList(list.shoppingListId).subscribe(y => {
      this.activeList = list;
      this.shoppingListService.getProductsForListNoImage(list.shoppingListId).subscribe(z => {
        this.activeList.productItemNames = z.map(a => a.productItemName);
        this.activeList.productIds = z.map(a => a.productId);
        this.offerProductForm.get('activeList').setValue(this.activeList);
      })
    })
  }

  public shoppingListIncludesItem(productId: any): boolean {
    if (this.isLoggedIn()) {
      return this.activeList?.productIds.includes(productId);
    }
    var contains = this.activeListLoggedOut?.products?.map(x => x.productId).includes(productId);
    return contains;
  }

  public isLoggedIn(): boolean {
    return this.userService.isLoggedIn();
  }

  public viewMore(): void {
    if (this.isBrochuresSelected) {
      this.lastResultsLength = this.brochures.length;
      this.brochurefilter.TakeCount += 16;
      this.getBrochuresByFilter();
    }
    else {
      this.lastResultsLength = this.products.length;
      this.productFilter.TakeCount += 16;
      this.getProductsByFilter();
    }
  }
}