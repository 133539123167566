import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDetailsResolver } from '../send-receive/services/user-details.resolver';
import { AuthGuard } from '../shared/guards/auth.guard';
import { WaybillOrderResolver } from './services/waybill-order.resolver';
import { WaybillDetailComponent } from './waybill-detail/waybill-detail.component';
import { WaybillListComponent } from './waybill-list/waybill-list.component';


const routes: Routes = [
  { path: ':lang/:role/my-posta/waybill', component: WaybillListComponent},
  //get('shipmentType'), route.paramMap.get('manifestNo'), route.paramMap.get('waybillNo'));
  { path: ':lang/:role/my-posta/waybill/:shipmentType/:manifestNo/:waybillNo', component: WaybillDetailComponent, resolve: { userDetails: UserDetailsResolver, waybill: WaybillOrderResolver }, canActivate: [AuthGuard]  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaybillRoutingModule { }
