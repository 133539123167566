import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { PriceMatrixService } from '../../../shared/price-matrix/services/price-matrix.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationString } from '../../../shared/price-matrix/entities/TranslationString';
import { ServiceType } from '../../../shared/price-matrix/enums/ServiceType';
import { Order } from '../../entities/Order';
import { Parcel } from '../../entities/parcel';
import { DefaultShipmentDimensions } from '../../entities/DefaultShipmentDimensions';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { TranslationHelper } from '../../../shared/helpers/translation-helper';
import { DefaultWeightInterval } from '../../../shared/price-matrix/entities/DefaultWeightInterval';


@Component({
  selector: 'app-quick-order-specification',
  templateUrl: './quick-order-specification.component.html',
  styleUrls: ['./quick-order-specification.component.scss', '../../new-order/send-and-receive/specification/send-receive-styles.scss']
})
export class QuickOrderSpecificationComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  sendReceiveForm: FormGroup

  @Input()
  order: Order;

  @Input()
  maxWeight: number | null = null;

  @Input()
  maxValue: number | null = null;

  @Output()
  items: EventEmitter<Array<Parcel>> = new EventEmitter();

  @Output()
  totalVolume: EventEmitter<number> = new EventEmitter();

  @Output()
  goBackToAddress: EventEmitter<boolean> = new EventEmitter();

  @Output()
  serviceType: EventEmitter<ServiceType> = new EventEmitter();

  @Input()
  incoTerms: string;

  public parcels: Array<Parcel> = new Array<Parcel>();

  public numberOfItems: number;

  public isParcelsSelected: boolean = true;

  public insuranceSelected: boolean = true;

  public unknownVolume: boolean = false;

  public parcelTotalVolumeUnknown: boolean = false;

  public itemNumberUnknown: boolean = false;

  public sizeTitle: string = "";

  public insuranceNotSelected: boolean = true;

  public insuranceIsSelected: any = true;

  public isKg: boolean = true;

  public isItems: boolean = true;

  @Input()
  fromCountryControl: FormControl;

  @Input()
  toCountryControl: FormControl;

  @Input()
  isParcel: string;

  @Input()
  fromDepot: boolean;

  @Input()
  toDepot: boolean;

  parcelValue: any;

  @Output()
  insurance: EventEmitter<boolean> = new EventEmitter();

  unknownParcelDimensions: boolean = false;

  attemptedToGetPrices: boolean = false;

  defaultShipmentDimensions: DefaultShipmentDimensions = new DefaultShipmentDimensions();

  //This is used to populate LWH fields upon weight input
  defaultWeightValues: Array<DefaultWeightInterval>;

  @Output()
  onClear: EventEmitter<boolean> = new EventEmitter();

  constructor(private priceMatrixService: PriceMatrixService,
    private translateService: TranslateService,
    private translateHelper: TranslationHelper,
    private errorHelper: ErrorHelper) {
  }

  ngOnInit(): void {

    Object.keys(this.sendReceiveForm.controls).forEach(key => {
      this.sendReceiveForm.get(key).valueChanges.subscribe(() => {
        this.items.emit(null);
      });
    });

    this.priceMatrixService.getDefaultWeightIntervals().subscribe(x => {this.defaultWeightValues = x; console.log(this.defaultWeightValues)});

    this.sendReceiveForm.get('isItems').setValue('true');
    this.sendReceiveForm.get('isKg').setValue('true');

    //TODO catch error

    //When viewing order
    if (!!this.order) {
      this.sendReceiveForm.get("referenceNumber").setValue(this.order.customerReference);
      // this.setParcelsForExistingOrder();
      if (this.order.insuranceSelected) {
        this.insuranceIsSelected = true;
      }
      else {
        this.insuranceNotSelected = true;
      }

      this.sendReceiveForm.get("totalWeight").setValue(this.order.weight);
      this.sendReceiveForm.get("valueOfGoods").setValue(this.order.valueOfGoods);
    }
    else {
      this.insuranceIsSelected = true;
    }

    this.sendReceiveForm.get("valueOfGoods").valueChanges.subscribe(x => {
      this.items.emit(null);
    });
    this.sendReceiveForm.get("totalWeight").valueChanges.subscribe(x => {
      this.items.emit(null);

      //Set default LWH if fields haven't been touched and is currently set to items instead of volume.
      if (this.isItems && !this.parcelInputsTouched())
      {
        setTimeout(() => {
          this.setParcelDefaultDimensions(+x)
        }, 600);
      }
    });
    this.sendReceiveForm.get("noOfItems").valueChanges.subscribe(x => {
      this.items.emit(null);
      this.onChange();
      this.translateHelper.formatNumberField(this.sendReceiveForm.get('noOfItems'));
    });

    this.toggleIsItems(true);
  }



  public hasErrors(field) {
    var errors = this.sendReceiveForm?.get(field)?.errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.sendReceiveForm?.get(field)?.errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public isFromToCountryFO() {
    if (this.sendReceiveForm.get("valueOfGoods").disabled)
    {
      this.sendReceiveForm.get("valueOfGoods").enable();
    }
    return false;
  }

  private setParcelsForExistingOrder() {
    if (!!this.order.parcels && this.order.parcels.length > 0) {
      this.isParcelsSelected = true;
      this.sendReceiveForm.get("numberOfPalettes").disable();
      this.sendReceiveForm.get("letterSize").setValidators(null);
      this.sendReceiveForm.get("letterSize").reset();
      this.sendReceiveForm.get("shipmentVolume").reset();
      this.parcelValue = 0;

      var parcels = this.order.parcels;

      //Add the first parcel and then the remaining using the functions we already have
      this.addInitialParcels();
      this.sendReceiveForm.get(`parcelLength0`).setValue(parcels[0].length);
      this.sendReceiveForm.get(`parcelWidth0`).setValue(parcels[0].width);
      this.sendReceiveForm.get(`parcelHeight0`).setValue(parcels[0].height);
      if (this.parcels[0].length == 0 || this.parcels[0].width == 0 || this.parcels[0].height == 0) {
        this.parcels[0].unknownDimensions = true;
        this.sendReceiveForm.addControl("totalParcelVolume", new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
        this.sendReceiveForm.get(`totalParcelVolume`).valueChanges.subscribe(newValue => {
          this.translateHelper.formatNumberField(this.sendReceiveForm.get('totalParcelVolume'));
        });
        this.translateService.onLangChange.subscribe(x => {
          this.translateHelper.convertNumberField(this.sendReceiveForm.get(`totalParcelVolume`));
        });
    

        var volumeFormControl = this.sendReceiveForm.get("totalParcelVolume")
        volumeFormControl.enable();
        volumeFormControl.setValue(this.order.volume);
        this.unknownParcelDimensions = true;
        this.disableAllParcelControls();
      }

      this.sendReceiveForm.get("noOfItems").setValue(parcels.length);
      this.numberOfItems = parcels.length;
      this.addAdditionalParcels();
      var i: number;
      for (i = 1; i < this.parcels.length; i++) {
        var currentParcel = parcels[i];
        this.sendReceiveForm.get(`parcelLength${i}`).setValue(currentParcel.length);
        this.sendReceiveForm.get(`parcelWidth${i}`).setValue(currentParcel.width);
        this.sendReceiveForm.get(`parcelHeight${i}`).setValue(currentParcel.height);
        if (!currentParcel.length || !currentParcel.width || !currentParcel.height) {
          this.parcels[i].unknownDimensions = true;
          if (!this.sendReceiveForm.get("totalParcelVolume")) {
            this.sendReceiveForm.addControl("totalParcelVolume", new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
            this.sendReceiveForm.get(`totalParcelVolume`).valueChanges.subscribe(newValue => {
              this.translateHelper.formatNumberField(this.sendReceiveForm.get('totalParcelVolume'));
            });
            this.translateService.onLangChange.subscribe(x => {
              this.translateHelper.convertNumberField(this.sendReceiveForm.get(`totalParcelVolume`));
            });
            var volumeFormControl = this.sendReceiveForm.get("totalParcelVolume")
            volumeFormControl.enable();
            volumeFormControl.setValue(this.order.volume);
            this.unknownParcelDimensions = true;
            this.disableAllParcelControls();
          }
        }
      }
    }
  }

  setParcelDefaultDimensions(weight: number) {
    if(!this.isKg){
      weight = weight / 1000;
    }
    var interval = this.defaultWeightValues.find(x => weight <= x.to);
    if (!!interval)
    {
      for (let i = 0; i < this.parcels.length; i++) {
        this.sendReceiveForm.get(`parcelLength${i}`).setValue(interval.length);
        this.sendReceiveForm.get(`parcelWidth${i}`).setValue(interval.width);
        this.sendReceiveForm.get(`parcelHeight${i}`).setValue(interval.height);
      }
    }
  }

  parcelInputsTouched(): boolean {
    for (let i = 0; i < this.parcels.length; i++) {
      if (!!this.sendReceiveForm.get(`parcelLength${i}`).value || !!this.sendReceiveForm.get(`parcelWidth${i}`).value || !!this.sendReceiveForm.get(`parcelHeight${i}`).value)
      {
        return true;
      }
    }
    return false;
  }

  ngOnChanges(): void {
  }

  public parcelsSelected(event: any) {
    this.isParcelsSelected = true;
    this.sendReceiveForm.get("numberOfPalettes").disable();
    this.sendReceiveForm.get("shipmentVolume").reset();

    if (!this.sendReceiveForm.get("noOfItems").value || this.sendReceiveForm.get("noOfItems").value === "0") {
      this.sendReceiveForm.get("noOfItems").setValue("1");
      this.numberOfItems = 1;
    }

    if (this.isParcelsSelected) {
      if (this.parcels.length === 0) {
        this.addInitialParcels();
      }
    }
    this.sendReceiveForm.get("shipmentLength").disable();
    this.sendReceiveForm.get("shipmentWidth").disable();
    this.sendReceiveForm.get("shipmentHeight").disable();
    this.sendReceiveForm.get("shipmentVolume").disable();

  }

  public setParcelTotalVolumeUnknown() {
    this.unknownVolume = !this.unknownVolume;
    this.parcelTotalVolumeUnknown = !this.parcelTotalVolumeUnknown;
    const volumeControl = this.sendReceiveForm.get("totalParcelVolume");

    if (this.parcelTotalVolumeUnknown)
    {
      volumeControl.reset();
      volumeControl.disable();
    }
    else
    {
      volumeControl.enable();
    }
  }

  private setDefaultVolumeShipment() {
    if (!!this.sendReceiveForm.get("numberOfPalettes")) {
      if (Number(this.sendReceiveForm.get("numberOfPalettes").value > 1)) {
        this.sendReceiveForm.get("shipmentVolume").setValue(this.defaultShipmentDimensions.volume * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
      else {
        this.sendReceiveForm.get("shipmentVolume").setValue(this.defaultShipmentDimensions.volume);
      }
    }

    this.sendReceiveForm.get("numberOfPalettes").valueChanges.subscribe(x => {
      if (!this.sendReceiveForm.get("shipmentVolume").touched) {
        this.sendReceiveForm.get("shipmentVolume").setValue(this.defaultShipmentDimensions.volume * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
    });
  }

  private setDefaultShipmentDimensions() {
    if (!!this.sendReceiveForm.get("numberOfPalettes")) {
      if (Number(this.sendReceiveForm.get("numberOfPalettes").value > 1)) {
        this.sendReceiveForm.get("shipmentLength").setValue(this.defaultShipmentDimensions.length * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
      else {
        this.sendReceiveForm.get("shipmentLength").setValue(this.defaultShipmentDimensions.length);
      }
    }

    if (!!this.sendReceiveForm.get("numberOfPalettes")) {
      if (Number(this.sendReceiveForm.get("numberOfPalettes").value > 1)) {
        this.sendReceiveForm.get("shipmentWidth").setValue(this.defaultShipmentDimensions.width * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
      else {
        this.sendReceiveForm.get("shipmentWidth").setValue(this.defaultShipmentDimensions.width);
      }
    }

    if (!!this.sendReceiveForm.get("numberOfPalettes")) {
      if (Number(this.sendReceiveForm.get("numberOfPalettes").value > 1)) {
        this.sendReceiveForm.get("shipmentHeight").setValue(this.defaultShipmentDimensions.height * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
      else {
        this.sendReceiveForm.get("shipmentHeight").setValue(this.defaultShipmentDimensions.height);
      }
    }

    this.sendReceiveForm.get("numberOfPalettes").valueChanges.subscribe(x => {
      if (!this.sendReceiveForm.get("shipmentLength").touched) {
        this.sendReceiveForm.get("shipmentLength").setValue(this.defaultShipmentDimensions.length * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
      if (!this.sendReceiveForm.get("shipmentWidth").touched) {
        this.sendReceiveForm.get("shipmentWidth").setValue(this.defaultShipmentDimensions.width * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
      if (!this.sendReceiveForm.get("shipmentHeight").touched) {
        this.sendReceiveForm.get("shipmentHeight").setValue(this.defaultShipmentDimensions.height * Number(this.sendReceiveForm.get("numberOfPalettes").value));
      }
    });
  }

  public shipmentVolumeSelected(event: any, existingOrder: boolean) {
    this.sendReceiveForm.get("shipmentHeight").reset();
    this.sendReceiveForm.get("shipmentLength").reset();
    this.sendReceiveForm.get("shipmentWidth").reset();
    this.sendReceiveForm.get("shipmentHeight").disable();
    this.sendReceiveForm.get("shipmentLength").disable();
    this.sendReceiveForm.get("shipmentWidth").disable();

    var shipmentVolumeControl = this.sendReceiveForm.get("shipmentVolume");
    shipmentVolumeControl.enable();

    if (!shipmentVolumeControl.value && !existingOrder) {
      this.setDefaultVolumeShipment();
    }
  }

  public setSizeValue(value) {
    //remove don't know flags for parcels on selecting a letter size
    this.unknownParcelDimensions = false;
    this.parcelTotalVolumeUnknown = false;
    this.sendReceiveForm.get("noOfItems").disable();
    this.sendReceiveForm.get("shipmentVolume").reset();

    if (this.isParcelsSelected) {
      this.isParcelsSelected = false;
      this.sendReceiveForm.get("parcel").reset();
      this.removeAllParcelRows();
    }
    this.items.emit(null);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.addAdditionalParcels();
    });
  }

  public parcelDimensionsUnknown() {
    this.unknownParcelDimensions = true;
    this.sendReceiveForm.addControl("totalParcelVolume", new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
    this.sendReceiveForm.get(`totalParcelVolume`).valueChanges.subscribe(newValue => {
      this.translateHelper.formatNumberField(this.sendReceiveForm.get('totalParcelVolume'));
    });
    this.translateService.onLangChange.subscribe(x => {
      this.translateHelper.convertNumberField(this.sendReceiveForm.get(`totalParcelVolume`));
    });
    this.disableAllParcelControls();
  }

  public setParcelDimensionsKnownUnknown(event: any, i: number) {
    this.parcels[i].unknownDimensions = true;
    if (this.parcels[i].unknownDimensions) {
      if (!this.sendReceiveForm.get("totalParcelVolume"))
      {
        this.sendReceiveForm.addControl("totalParcelVolume", new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
        this.sendReceiveForm.get(`totalParcelVolume`).valueChanges.subscribe(newValue => {
          this.translateHelper.formatNumberField(this.sendReceiveForm.get('totalParcelVolume'));
        });
        this.translateService.onLangChange.subscribe(x => {
          this.translateHelper.convertNumberField(this.sendReceiveForm.get(`totalParcelVolume`));
        });
      }
      if (!this.parcelTotalVolumeUnknown)
      {
        this.sendReceiveForm.get("totalParcelVolume").enable();
      }
      this.disableAllParcelControls();
    }
    else {
      this.sendReceiveForm.get("totalParcelVolume").disable();
      this.enableAllParcelControls();
    }
  }

  public getPrices() {

    if(this.parcelTotalVolumeUnknown)
    {
      return;
    }
    
    this.sendReceiveForm.markAllAsTouched();
    this.attemptedToGetPrices = true;
    if (!this.sendReceiveForm.valid) {
      //temp work around for letter being required when coming from find prices
        this.items.emit(null);
        this.insurance.emit(null);
        this.totalVolume.emit(null);
        return;
      }

    //Emit Volume for letter, parcel or shipment
    this.EmitTotalVolumeForSizeSelected();

    if (!this.isItems)
    {
      if (!!this.sendReceiveForm.get("totalParcelVolume"))
      {
        this.sendReceiveForm.get("totalParcelVolume").reset();
        this.sendReceiveForm.get("totalParcelVolume").disable();
      }
      this.unknownParcelDimensions = false;
      this.items.emit(new Array<Parcel>(new Parcel()));
    }else {
      this.items.emit(this.parcels);
    }
    this.insurance.emit(this.insuranceSelected);
    this.sendReceiveForm.markAsPristine();
  }

  private EmitTotalVolumeForSizeSelected() {

    if (!this.isItems)
    {
      this.totalVolume.emit(+this.translateHelper.getNumber(this.sendReceiveForm.get("noOfItems").value));
    }
    //TODO remove anything related to service type as it's now redunant
    if (!!this.parcels && this.parcels.length > 0) {
      var totalParcelVolume: number = 0;
      
      //If to and from FO then we need to calculate per item basis so instead of the sum of all parcels, send the highest volume and see if it's within the constraints.
      // if (this.isFromToCountryFO())
      // {
      //   for (let i = 0; i < this.parcels.length; i++) {
      //     var parcelVolume = this.translateHelper.getNumber(this.sendReceiveForm.get(`parcelLength${i}`).value) * this.translateHelper.getNumber(this.sendReceiveForm.get(`parcelWidth${i}`).value) * this.translateHelper.getNumber(this.sendReceiveForm.get(`parcelHeight${i}`).value);
      //     if (totalParcelVolume < parcelVolume)
      //     {
      //       totalParcelVolume = parcelVolume;
      //     }
      //   }
      // }
      // else {
        for (let i = 0; i < this.parcels.length; i++) {
          totalParcelVolume += (this.translateHelper.getNumber(this.sendReceiveForm.get(`parcelLength${i}`).value) * this.translateHelper.getNumber(this.sendReceiveForm.get(`parcelWidth${i}`).value) * this.translateHelper.getNumber(this.sendReceiveForm.get(`parcelHeight${i}`).value));
        }
      // }

      //Divide by 1000000 to convert to m cubed
      var mCubed = totalParcelVolume / 1000000;
      this.totalVolume.emit(mCubed);
      //this.serviceType.emit(ServiceType.Parcel);
    }
    if (!!this.sendReceiveForm.get("totalParcelVolume") && this.sendReceiveForm.get("totalParcelVolume").value != null && this.isParcelsSelected) {
      this.totalVolume.emit(this.translateHelper.getNumber(this.sendReceiveForm.get("totalParcelVolume").value));
      //this.serviceType.emit(ServiceType.Parcel);
    }
  }

  public setSameAsPreviousParcel(event: any, i: number) {
    this.parcels[i].sameAsPrevious = true;

    if (i !== 0) {
      this.parcels[i].length = this.parcels[i - 1].length;
      this.parcels[i].width = this.parcels[i - 1].width;
      this.parcels[i].height = this.parcels[i - 1].height;
    }
  }

  public setItemNumberUnknown() {
    this.itemNumberUnknown = !this.itemNumberUnknown;

    if (this.itemNumberUnknown) {
      this.sendReceiveForm.get("noOfItems").setValue("1");
      // this.sendReceiveForm.get("noOfItems").setValue("-");
      this.sendReceiveForm.get("noOfItems").disable();
      if(!this.isItems)
      {
       this.parcelTotalVolumeUnknown = true;
      }
    }
    else {
      this.parcelTotalVolumeUnknown = false;
      this.sendReceiveForm.get("noOfItems").enable();
      this.sendReceiveForm.get("noOfItems").setValue("1");
      // this.parcelTotalVolumeUnknown = false;
    }
  }

  public setItemNumberUnknownFalse() {
    this.itemNumberUnknown = false;
    if (this.sendReceiveForm.get("noOfItems").disabled)
    {
      this.sendReceiveForm.get("noOfItems").enable();
    }
  }

  public changeSetAsPrevious(i: number) {
    this.parcels[i].sameAsPrevious = !this.parcels[i].sameAsPrevious;
  }

  public setUnknownVolume()
  {
    this.unknownVolume = !this.unknownVolume;
    this.parcelTotalVolumeUnknown = !this.parcelTotalVolumeUnknown

    const volumeControl = this.sendReceiveForm.get("totalParcelVolume");
    if (!this.parcelTotalVolumeUnknown)
    {
      volumeControl.enable();
    }
    else {
      volumeControl.disable();
    }
  }

  public changeSetAsKnownUnknown(i: number) {
    this.parcels[i].unknownDimensions = !this.parcels[i].unknownDimensions;

    if (this.parcels[i].unknownDimensions) {
      // if (this.itemNumberUnknown)
      // {
      //   this.parcelTotalVolumeUnknown = true;
      //   return;
      // }

      //Reset any values which may have been entered for the parcel row which has 'don't know' selected.
      this.sendReceiveForm.get(`parcelLength${i}`).reset();
      this.sendReceiveForm.get(`parcelWidth${i}`).reset();
      this.sendReceiveForm.get(`parcelHeight${i}`).reset();

      this.sendReceiveForm.addControl("totalParcelVolume", new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`totalParcelVolume`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get('totalParcelVolume'));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`totalParcelVolume`));
      });
      this.sendReceiveForm.get("totalParcelVolume").enable();

      this.sendReceiveForm.get(`totalParcelVolume`).valueChanges.subscribe(value => {
        this.items.emit(null);
      });
      this.disableAllParcelControls();
    }
    else {
      this.sendReceiveForm.get(`parcelLength${i}`).disable();
      this.sendReceiveForm.get(`parcelWidth${i}`).disable();
      this.sendReceiveForm.get(`parcelHeight${i}`).disable();
      this.sendReceiveForm.get("totalParcelVolume").reset();
      this.sendReceiveForm.get("totalParcelVolume").disable();
      // this.enableAllParcelControls();
      // this.parcelTotalVolumeUnknown = false;
    }

    if (this.parcels.filter(x => x.unknownDimensions).length > 0) {
      this.unknownParcelDimensions = true;
    }
    else {
      this.unknownParcelDimensions = false;
      this.enableAllParcelControls();
    }
  }

  public onChange() {
    if (this.isItems)
    {
      var noOfItemControl = this.sendReceiveForm.get("noOfItems");
      if (noOfItemControl.disabled)
      {
        noOfItemControl.enable();
      }
      if (this.isParcelsSelected) {
        if (this.parcels.length === 0 && !noOfItemControl.value) {
          this.addInitialParcels();
        }
        else if (this.parcels.length < noOfItemControl.value) {
          this.setItemNumberUnknownFalse();
          this.addAdditionalParcels();
        }
        else if (this.parcels.length >= noOfItemControl.value && +noOfItemControl.value != 0)
        {
          this.removeParcelRows();
        }
        else {
          this.removeAllParcelRows();
        }
      }
    }
  }

  public addInitialParcels() {
    this.numberOfItems = 1;
    for (let i = 0; i < this.numberOfItems; i++) {

      this.sendReceiveForm.addControl(`parcelLength${i}`, new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`parcelLength${i}`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get(`parcelLength${i}`));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`parcelLength${i}`));
      });
      this.sendReceiveForm.addControl(`parcelWidth${i}`, new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`parcelWidth${i}`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get(`parcelWidth${i}`));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`parcelWidth${i}`));
      });
      this.sendReceiveForm.addControl(`parcelHeight${i}`, new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`parcelHeight${i}`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get(`parcelHeight${i}`));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`parcelHeight${i}`));
      });

      var parcel = new Parcel();
      parcel.parcelNo = i + 1;
      this.parcels.push(parcel);

      this.sendReceiveForm.get(`parcelLength${i}`).valueChanges.subscribe(value => {
        if (!!value) {
          this.parcels[i].length = this.translateHelper.getNumber(value);
        }
        this.items.emit(null);
      });
      this.sendReceiveForm.get(`parcelWidth${i}`).valueChanges.subscribe(value => {
        if (!!value) {
          this.parcels[i].width = this.translateHelper.getNumber(value);
        }
        this.items.emit(null);
      });
      this.sendReceiveForm.get(`parcelHeight${i}`).valueChanges.subscribe(value => {
        if (!!value) {
          this.parcels[i].height = this.translateHelper.getNumber(value);
        }
        this.items.emit(null);
      });
    }
  }

  public addAdditionalParcels() {
    const numDiff = +this.sendReceiveForm.get("noOfItems").value - this.parcels.length;
    for (let i = 0; i < numDiff; i++) {

      const newParcelIndex = this.parcels.length;

      this.sendReceiveForm.addControl(`parcelLength${newParcelIndex}`, new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`parcelLength${newParcelIndex}`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get(`parcelLength${newParcelIndex}`));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`parcelLength${newParcelIndex}`));
      });
      this.sendReceiveForm.addControl(`parcelWidth${newParcelIndex}`, new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`parcelWidth${newParcelIndex}`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get(`parcelWidth${newParcelIndex}`));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`parcelWidth${newParcelIndex}`));
      });
      this.sendReceiveForm.addControl(`parcelHeight${newParcelIndex}`, new FormControl('', [Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]));
      this.sendReceiveForm.get(`parcelHeight${newParcelIndex}`).valueChanges.subscribe(newValue => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get(`parcelHeight${newParcelIndex}`));
      });
      this.translateService.onLangChange.subscribe(x => {
        this.translateHelper.convertNumberField(this.sendReceiveForm.get(`parcelHeight${newParcelIndex}`));
      });
      if (this.unknownParcelDimensions) {
        this.sendReceiveForm.get(`parcelLength${newParcelIndex}`).disable();
        this.sendReceiveForm.get(`parcelWidth${newParcelIndex}`).disable();
        this.sendReceiveForm.get(`parcelHeight${newParcelIndex}`).disable();
      }

      var parcel = new Parcel();
      parcel.parcelNo = newParcelIndex + 1;
      this.parcels.push(parcel);

      this.sendReceiveForm.get(`parcelLength${newParcelIndex}`).valueChanges.subscribe(value => {
        if (!!value) {
          this.parcels[newParcelIndex].length = this.translateHelper.getNumber(value);
        }
        this.items.emit(null);
      });
      this.sendReceiveForm.get(`parcelWidth${newParcelIndex}`).valueChanges.subscribe(value => {
        if (!!value) {
          this.parcels[newParcelIndex].width = this.translateHelper.getNumber(value);
        }
        this.items.emit(null);
      });
      this.sendReceiveForm.get(`parcelHeight${newParcelIndex}`).valueChanges.subscribe(value => {
        if (!!value) {
          this.parcels[newParcelIndex].height = this.translateHelper.getNumber(value);
        }
        this.items.emit(null);
      });
    }
  }

  public removeParcelRows() {
    const numDiff = this.parcels.length - +this.sendReceiveForm.get("noOfItems").value;
    var j = this.parcels.length;
    for (let i = 0; i < numDiff; i++) {
      this.parcels.splice(this.parcels.length - 1, 1);
      this.sendReceiveForm.removeControl(`parcelLength${j - 1}`)
      this.sendReceiveForm.removeControl(`parcelWidth${j - 1}`)
      this.sendReceiveForm.removeControl(`parcelHeight${j - 1}`)
      j--;
    }
  }

  public removeAllParcelRows() {
    for (let i = 0; i <= this.parcels.length; i++) {
      this.sendReceiveForm.removeControl(`parcelLength${i}`)
      this.sendReceiveForm.removeControl(`parcelWidth${i}`)
      this.sendReceiveForm.removeControl(`parcelHeight${i}`)
    }
    this.parcels = new Array<Parcel>();
  }

  public disableAllParcelControls() {
    const parcelLength = this.parcels.length;
    if (parcelLength > 0) {
      for (let i = 0; i < parcelLength; i++) {
        this.sendReceiveForm.get(`parcelLength${i}`).disable();
        this.sendReceiveForm.get(`parcelWidth${i}`).disable();
        this.sendReceiveForm.get(`parcelHeight${i}`).disable();
      }
    }
  }

  public enableAllParcelControls() {
    const parcelLength = this.parcels.length;
    if (parcelLength > 0) {
      for (let i = 0; i < parcelLength; i++) {
        this.sendReceiveForm.get(`parcelLength${i}`).enable();
        this.sendReceiveForm.get(`parcelWidth${i}`).enable();
        this.sendReceiveForm.get(`parcelHeight${i}`).enable();

        this.sendReceiveForm.get(`parcelLength${i}`).markAsUntouched();
        this.sendReceiveForm.get(`parcelWidth${i}`).markAsUntouched();
        this.sendReceiveForm.get(`parcelHeight${i}`).markAsUntouched();
      }
    }
  }

  public setInsuranceTrue() {
    this.insuranceSelected = true;
    this.items.emit(null);
  }

  public setInsuranceFalse() {
    this.insuranceSelected = false;
    this.items.emit(null);
  }

  public toggleIsKg(isKg: boolean)
  {
    this.isKg = isKg;
    this.sendReceiveForm.get('isKg').setValue(String(isKg));
  }

  public toggleIsItems(isItems: boolean)
  {
    this.itemNumberUnknown = false;
    if (this.sendReceiveForm.get("noOfItems").disabled)
    {
      this.sendReceiveForm.get("noOfItems").enable();
    }
    this.parcelTotalVolumeUnknown = false;
    const volumeControl = this.sendReceiveForm.get("totalParcelVolume");
    if (!isItems)
    {
      this.totalVolume.emit(null);
      this.sendReceiveForm.get("noOfItems").setValue("0");
      this.sendReceiveForm.get("noOfItems").setValidators([Validators.required, ErrorHelper.greaterThanZeroValidator, ErrorHelper.numberOnlyValidator]);
      this.sendReceiveForm.get("noOfItems").updateValueAndValidity();
      this.removeAllParcelRows();
      var temp = this.parcels;
      // if (!!volumeControl)
      // {
      //   volumeControl.reset();
      //   volumeControl.disable();
      // }
      this.unknownParcelDimensions = false;
      this.unknownVolume = false;
    }
    else {
      this.sendReceiveForm.get("noOfItems").setValue("1");
      this.numberOfItems = 1;
      if (this.isParcelsSelected) {
        if (this.parcels.length === 0) {
          this.addInitialParcels();
        }
        else if (this.parcels.length < this.sendReceiveForm.get("noOfItems").value) {
          this.addAdditionalParcels();
          this.setItemNumberUnknownFalse();
        }
        else if (this.parcels.length >= this.sendReceiveForm.get("noOfItems").value)
        {
          this.removeParcelRows();
        }
        else {
          this.removeAllParcelRows();
        }
      }
      this.sendReceiveForm.get("noOfItems").setValidators([Validators.required, ErrorHelper.integerOnlyValidator]);
      this.sendReceiveForm.get("noOfItems").updateValueAndValidity();
      this.sendReceiveForm.get("noOfItems").valueChanges.subscribe(x => {
        this.translateHelper.formatNumberField(this.sendReceiveForm.get('noOfItems'));
      });
      // if (!!volumeControl)
      // {
      //   volumeControl.enable();
      // }
    }
    this.isItems = isItems;
    this.sendReceiveForm.get('isItems').setValue(String(isItems));
  }

  public clearAll() {
    this.isParcelsSelected = false;
    this.insuranceSelected = true;
    this.itemNumberUnknown = false;
    this.parcelTotalVolumeUnknown = false;
    this.unknownParcelDimensions = false;
    this.parcelTotalVolumeUnknown = false;
    this.removeAllParcelRows();
    this.onClear.emit(true);

    this.sendReceiveForm.get("shipmentHeight").disable();
    this.sendReceiveForm.get("shipmentLength").disable();
    this.sendReceiveForm.get("shipmentWidth").disable();
    this.sendReceiveForm.get("shipmentVolume").disable();
  }

  public navigateToAddresses() {
    this.goBackToAddress.emit(true);
  }

  public otherSelected() {
    if (!!this.sendReceiveForm.get("selectTypeOfGoods") && this.sendReceiveForm.get("selectTypeOfGoods").value === "Other") {
      if (!this.sendReceiveForm.get("otherTypeGoods")) {
        this.sendReceiveForm.addControl("otherTypeGoods", new FormControl('', Validators.required))
      }
      return true;
    }
    if (!!this.sendReceiveForm.get("otherTypeGoods")) {
      this.sendReceiveForm.get("otherTypeGoods").reset();
    }
    return false;
  }

  private setFromPriceItemSelection() {
    if (this.isParcel === "true") {
      this.isParcelsSelected = true;
      this.parcelValue = 0;
      this.parcelsSelected(null);
      this.sendReceiveForm.get("letterSize").clearValidators();
      this.sendReceiveForm.get("letterSize").updateValueAndValidity();

    }
    }

  public getLetterSizeNameForLanguage(letterName: TranslationString, description: TranslationString): string {
    switch (this.translateService.currentLang) {
      case "en":
        return `${letterName.EN} (${description.EN})`;
      case "fo":
        return `${letterName.FO} (${description.FO})`;
    }
  }
}
