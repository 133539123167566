import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomShipmentType } from '../../../shared/entities/customShipmentType';
import { Invoice } from '../../../shared/entities/Invoice';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { StaticContentHelper } from '../../../shared/helpers/static-content-helper';
import { ProductType } from '../../../shared/price-matrix/enums/ProductType';
import { CustomShipmentTypeService } from '../../../shared/services/custom-shipment-types.service';
import { StaticContentService } from '../../../shared/services/static-content.service';
import { Order } from '../../entities/Order';
import { ServiceOption } from '../../entities/serviceOption';
import { CustomsItemType } from '../../entities/CustomsItemType';
import { BuyPostageItemTypesComponent } from '../../buy-postage/international-goods/buy-postage-item-types/buy-postage-item-types.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  @Input()
  stepper: MatStepper;

  @Input()
  sendReceiveForm: FormGroup;

  @Input()
  toFromAddressFormGroup: FormGroup;

  @Input()
  order: Order;

  @Input()
  itemTypes: CustomsItemType[]

  public readyForCollection: boolean = null;

  public otherSelected: boolean = false;

  public today: Date = new Date();

  public clearFiles: boolean = false;

  public shipmentTypes: Array<CustomShipmentType>;

  public existingOrderNotReadyForCollection: boolean;

  public existingOrderReadyForCollection: boolean;

  public existingOrderInvoices: Array<Invoice>;

  public managedCustomsClearanceChecked: boolean = false;

  public filesToRemove: Array<Invoice>;

  public filesToAdd: Array<Invoice>;

  public linkItem1: any;

  public linkItem2: any;

  public collectionOption: any;

  public showCustomsInformation1: boolean = true;
  public showTypeOfShipment: boolean = true;
  public showUploadInvoice: boolean = true;
  public showIsReadyForCollection: boolean = true;
  public showManagedCustomsClearance: boolean = true;
  public showItemTypes: boolean = true;

  @ViewChild(BuyPostageItemTypesComponent) itemTypesComponent!: BuyPostageItemTypesComponent;

  @Output()
  public onClear: EventEmitter<any> = new EventEmitter();

  @Input()
  showRequiredError: boolean;

  @Input()
  fromDepot: boolean;

  @Input()
  toDepot: boolean;

  @Input()
  serviceOption: ServiceOption;

  @Input()
  hideOrderDetails: boolean;

  managedCustomCost: number;

  toFromIsFO: boolean = false;

  currentToCountryCode: string;

  currentFromCountryCode: string;

  @Output()
  public customsClearanceSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public uploadedFiles: EventEmitter<any> = new EventEmitter();

  @Output()
  public selectedShipmentType: EventEmitter<CustomShipmentType> = new EventEmitter();

  @Input()
  psModule: any;

  @Input()
  totalCost: number;

  constructor(private customShipmentTypeService: CustomShipmentTypeService, private translateService: TranslateService, private errorHelper: ErrorHelper, private staticContentService: StaticContentService, private router: Router) {
    const newDate = new Date();
    this.today.setDate(newDate.getDate());
  }

  ngOnInit(): void {

    this.sendReceiveForm.get("shipmentTypeOther").disable();

    this.customShipmentTypeService.getAll().subscribe(x => {
      this.shipmentTypes = x;

      // Any status above 1 means it's not a quote and will have a value
      if (!!this.order && this.order.orderStateId > 1) {
        var orderShipmentType = this.shipmentTypes.find(x => x.customsShipmentTypeId == this.order.customsShipmentTypeId);
        this.selectedShipmentType.emit(orderShipmentType);
      }
      else {
        //If not an order emit purchase/commercial as default
        this.selectedShipmentType.emit(this.shipmentTypes.find(x => x.customsShipmentTypeId == 1));
      }
    })

    if (!!this.order && this.order.orderStateId > 1) {
      if (!!this.order.collectionDate) {
        if (new Date() < new Date(this.order.collectionDate)) {
          this.onSelectNotReadyForCollection(null);
          this.existingOrderNotReadyForCollection = true;
          this.sendReceiveForm.get("collectionDate").setValue(this.order.collectionDate);
        }
        else {
          this.existingOrderReadyForCollection = true;
          this.readyForCollection = true;
        }
      }
      this.sendReceiveForm.get("definitionOfGoods").setValue(this.order.definitionOfGoods);
      this.sendReceiveForm.get("comments").setValue(this.order.comments);

      if (!!this.order.invoices) {
        this.existingOrderInvoices = this.order.invoices;
      }

      if (!!this.order.customsClearanceExtraJson) {
        this.managedCustomsClearanceChecked = true;
      }
    }

    // this.showHideFields();

    // this.sendReceiveForm.valueChanges.subscribe(x => {
    //   this.showHideFields();
    // })

    this.toFromAddressFormGroup.valueChanges.subscribe(x => {
      if (this.isDomestic())
      {
        this.sendReceiveForm.get("domesticPackageContents").setValidators([Validators.required, Validators.maxLength(255)]);
      }
      else {
        this.sendReceiveForm.get("domesticPackageContents").clearValidators();
      }
    })
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['psModule']){
      this.linkItem1 = this.psModule.Item3Link;
      this.linkItem2 = this.psModule.Item4Link;
    }

    
    // if (this.isDomestic())
    // {
    //   this.sendReceiveForm.get("domesticPackageContents").setValidators([Validators.required]);
    // }
    // else {
    //   this.sendReceiveForm.get("domesticPackageContents").clearValidators();
    // }

  }

  public hasErrors(field) {
    var errors = this.sendReceiveForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.sendReceiveForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public setCustomsClearance() {
    this.managedCustomsClearanceChecked = !this.managedCustomsClearanceChecked;
    this.customsClearanceSelected.emit(this.managedCustomsClearanceChecked);
  }

  public getManagedCustomCost() {

    if (!!this.serviceOption) {
      if (this.serviceOption.ManagedCustomCost > 0) {
        this.managedCustomCost = this.serviceOption.ManagedCustomCost;
        return this.managedCustomCost;
      }
    }
    return undefined;
  }

  public setFilesToAdd(invoices: Array<Invoice>) {
    this.filesToAdd = invoices;
  }

  public setFilesToRemove(invoices: Array<Invoice>) {
    this.filesToRemove = invoices;
  }

  public getFilesToRemove() {
    return this.filesToRemove;
  }

  public getFilesToAdd() {
    return this.filesToAdd;
  }

  public onSelectReadyForCollection(event: any) {
    this.collectionOption = "yes";
    const today = new Date();
    // const tomorrow = new Date(today.setDate(today.getDate()));
    this.sendReceiveForm.get("collectionDate").setValue(new Date());
    this.readyForCollection = true;
  }

  public onSelectNotReadyForCollection(event: any) {
    this.collectionOption = "no";
    const today = new Date();
    // const tomorrow = new Date(today.setDate(today.getDate()));
    this.sendReceiveForm.get("collectionDate").setValue(new Date());
    this.readyForCollection = false;
  }

  public onSelectCommerical(event: any, shipmentType: CustomShipmentType) {
    //If purchase / commerical
    if (shipmentType.customsShipmentTypeId == 999) {
      this.otherSelected = true;
      this.sendReceiveForm.get("shipmentTypeOther").enable();
    }
    else{
      this.otherSelected = false;
      this.sendReceiveForm.get("shipmentTypeOther").disable();
    }
    this.selectedShipmentType.emit(shipmentType);
  }

  public checkPurchaseCommercial(shipmentType: CustomShipmentType) {
    if (!!this.order && this.order.orderStateId > 1) {
      if (this.order.customsShipmentTypeId == shipmentType.customsShipmentTypeId) {
        return true;
      }
    }
    else if (shipmentType.customsShipmentTypeId == 1) {
      return true;
    }
    return undefined;
  }

  public setUploadedFiles(event: any) {
    this.uploadedFiles.emit(event);
    this.clearFiles = false;
  }

  public setShowRequiredError(): boolean {
    return this.showRequiredError;
  }

  public checkFilesUploaded() {
    console.log(this.sendReceiveForm);
    console.log(this.toFromAddressFormGroup);

    if (!this.sendReceiveForm.get('uploadedFiles').valid) {
      this.showRequiredError = true;
    }
    if(this.itemTypesComponent && !this.itemTypesComponent.CheckValid()){
      this.showRequiredError = true;
    }
  }

  checkProceedDisabled(){
    if(this.itemTypesComponent && !this.itemTypesComponent.CheckValid()){
      return true;
    }
  }

  public clearAll() {
    this.sendReceiveForm.get("comments").reset();
    this.collectionOption = undefined;
    this.onClear.emit(true);
    this.clearFiles = true;
  }

  public navigateToPreviousPage() {
    this.stepper.selectedIndex = 1;
  }

  public getCustomsShipmentTypeName(shipmentType: CustomShipmentType) {
    switch (this.translateService.currentLang) {
      case "en":
        return `${shipmentType.customsShipmentTypeNameEN}`;
      case "fo":
        return `${shipmentType.customsShipmentTypeNameFO}`;
    }
  }

  public isFromFo() {
    return !this.toFromAddressFormGroup.get("fromCountry").disabled && !!this.toFromAddressFormGroup.get("fromCountry").value && this.toFromAddressFormGroup.get("fromCountry").value.countryCode === "FO";
  }

  public isFromDk() {
    return !!this.toFromAddressFormGroup.get("fromCountry").value && this.toFromAddressFormGroup.get("fromCountry").value.countryCode === "DK";
  }

  public isToFo() {
    return !this.toFromAddressFormGroup.get("toCountry").disabled && !!this.toFromAddressFormGroup.get("toCountry").value && this.toFromAddressFormGroup.get("toCountry").value.countryCode === "FO";
  }

  public isDomestic() {
    if(!this.toFromAddressFormGroup.get("fromCountry") && !this.toFromAddressFormGroup.get("toCountry"))
      return false; // Quick Journey - Depot to FO

    return !this.toFromAddressFormGroup.get("fromCountry").disabled && !this.toFromAddressFormGroup.get("toCountry").disabled && !!this.toFromAddressFormGroup.get("fromCountry").value &&
    !!this.toFromAddressFormGroup.get("toCountry").value &&
    this.toFromAddressFormGroup.get("fromCountry").value.countryCode === this.toFromAddressFormGroup.get("toCountry").value.countryCode;
  }

  public openRouteInNewWindow(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  public isBuyPostage(serviceOption: ServiceOption): boolean{
    return true; //DEV
  }

  public isPostParcel(serviceOption: ServiceOption): boolean {
    return (serviceOption.ProductType == ProductType.Postage ||
      serviceOption.ProductType == ProductType.Postage_Plus || serviceOption.ProductType == ProductType.Parcel)
  }

  public showHideFields(serviceOption: ServiceOption) {
    var value = +this.sendReceiveForm.get("valueOfGoods")?.value;

    this.sendReceiveForm.get("comments").enable();
    this.sendReceiveForm.get("domesticPackageContents").enable();
    if (!serviceOption || !serviceOption.ProductType) {
      return;
    }
    if (this.toDepot && this.isFromFo() && !this.isPostParcel(serviceOption)) {
      this.showCustomsInformation1 = true;
      this.showTypeOfShipment = false;
      this.showUploadInvoice = true;
      this.showManagedCustomsClearance = false;
      this.showIsReadyForCollection = true;
      this.showItemTypes = false;
      this.sendReceiveForm.get("collectionDate").enable();
      this.sendReceiveForm.get("definitionOfGoods").enable();
      this.sendReceiveForm.get("uploadedFiles").setValidators([Validators.required]);
      this.sendReceiveForm.get("uploadedFiles").updateValueAndValidity();
      return;
    }
    if (this.isFromFo() && this.isToFo()) {
      this.showCustomsInformation1 = false;
      this.showTypeOfShipment = false;
      this.showUploadInvoice = false;
      this.showManagedCustomsClearance = false;
      this.showIsReadyForCollection = false;
      this.showItemTypes = false;
      this.sendReceiveForm.get("collectionDate").disable();
      if (serviceOption.ProductType == ProductType.DomesticTransport) {
        this.showIsReadyForCollection = true;
        this.sendReceiveForm.get("collectionDate").enable();
      }
      this.sendReceiveForm.get("definitionOfGoods").disable();
      this.sendReceiveForm.get("uploadedFiles").disable();

      return;
    }
    if (this.isFromFo() && this.toDepot && !this.isPostParcel(serviceOption)) {
      this.showCustomsInformation1 = false;
      this.showTypeOfShipment = false;
      this.showUploadInvoice = false;
      this.showManagedCustomsClearance = false;
      this.showIsReadyForCollection = true;
      this.showItemTypes = false;
      this.sendReceiveForm.get("collectionDate").enable();
      this.sendReceiveForm.get("definitionOfGoods").disable();
      this.sendReceiveForm.get("uploadedFiles").setValidators(null);
      this.sendReceiveForm.get("uploadedFiles").updateValueAndValidity();
      return;
    }
    if (this.fromDepot && this.isToFo() && !this.isPostParcel(serviceOption)) {
      this.showCustomsInformation1 = true;
      this.showTypeOfShipment = false;
      this.showUploadInvoice = true;
      this.showManagedCustomsClearance = true;
      this.showIsReadyForCollection = false;
      this.showItemTypes = false;
      this.sendReceiveForm.get("collectionDate").disable();
      this.sendReceiveForm.get("definitionOfGoods").enable();
      this.sendReceiveForm.get("uploadedFiles").setValidators([Validators.required]);
      this.sendReceiveForm.get("uploadedFiles").updateValueAndValidity();
      return;
    }
    if (this.isFromDk() && this.isToFo() && !this.isPostParcel(serviceOption)) {
      this.showCustomsInformation1 = true;
      this.showTypeOfShipment = false;
      this.showUploadInvoice = true;
      this.showManagedCustomsClearance = true;
      this.showIsReadyForCollection = true;
      this.showItemTypes = false;
      this.sendReceiveForm.get("collectionDate").enable();
      this.sendReceiveForm.get("definitionOfGoods").enable();
      this.sendReceiveForm.get("uploadedFiles").setValidators([Validators.required]);
      this.sendReceiveForm.get("uploadedFiles").updateValueAndValidity();
      return;
    }
    if (this.isFromFo() && !this.isToFo()) {
      if (this.isPostParcel(serviceOption)) {
        this.showCustomsInformation1 = true;
        this.showTypeOfShipment = true;
        this.showUploadInvoice = false;
        this.showManagedCustomsClearance = false;
        this.showIsReadyForCollection = false;
        this.showItemTypes = true;
        this.sendReceiveForm.get("collectionDate").disable();
        this.sendReceiveForm.get("definitionOfGoods").enable();
        this.sendReceiveForm.get("uploadedFiles").disable();
      }
      else {
        this.showCustomsInformation1 = true;
        this.showTypeOfShipment = false;
        this.showUploadInvoice = true;
        this.showManagedCustomsClearance = false;
        this.showIsReadyForCollection = true;
        this.showItemTypes = false;
        this.sendReceiveForm.get("collectionDate").enable();
        this.sendReceiveForm.get("definitionOfGoods").enable();
        this.sendReceiveForm.get("uploadedFiles").setValidators([Validators.required]);
        this.sendReceiveForm.get("uploadedFiles").updateValueAndValidity();
      }
      return;
    }

  }
}
