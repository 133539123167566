import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(private httpClient: HttpClient) {
    }

    public saveToAddressBook(address: any): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/api/AddressBook`, address)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    public getAll(): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/api/AddressBook`);

    }

    public getParcelLockers(): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/api/AddressBook/parcel_locker`);
    }

    public getPostcodes(): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/api/AddressBook/Postcodes`);
    }

    public update(address: any): Observable<object>
    {
      return this.httpClient.put(`${environment.apiUrl}/api/AddressBook`, address);
    }

    public favouriteAddress(addressId: string, favourite: boolean): Observable<object>
    {
      return this.httpClient.put(`${environment.apiUrl}/api/AddressBook/${addressId}?IsFavourite=${favourite}`, null);
    }

    public delete(addressId: string): Observable<object>
    {
        return this.httpClient.delete(`${environment.apiUrl}/api/AddressBook/${addressId}`);
    }

    // this.http.get(`/Authentication/MinlykilLogin`, { responseType: 'text'}).subscribe( 
}