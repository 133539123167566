<div class="parent-container">
    <!-- <div class="specification-container note-container">
        <div class="note-text">{{'send_receive.send_receive.step_one.info' | translate}}</div>
    </div> -->
    <div class="parent-container">
        <div class="specification-container main-body-container">
            <div class="row full-width">
                <div class="col-lg-6">
                    <div class="border-bottom">
                        <h2>{{'send_receive.send_receive.step_one.from' | translate}}</h2>
                    </div>
                    <div>
                        <app-posta-depot-address [toFromAddressform]="sendReceiveForm" [isSender]="true"></app-posta-depot-address>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="border-bottom">
                        <h2 class="margin-top-mobile">{{'send_receive.send_receive.step_one.to' | translate}}</h2>
                    </div>
                    <div style="padding-left: 0">
                        <div class="to-option-container border-bottom">
                        
                                <div class="row">
                                    <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                                        floatLabel="never" appearance="outline">
                                        <input class="text-input" matInput id="toFirstName"
                                            placeholder="{{'address_book.address_details.first_name' | translate}}" name="toFirstName"
                                            formControlName="toFirstName" required>
                                            <mat-error *ngIf="hasErrors('toFirstName')"><app-field-error-message errorMessage="{{getError('toFirstName')}}"></app-field-error-message></mat-error>
                                    </mat-form-field>
                                </div>
                        
                                <div class="row">
                                    <mat-form-field class="col-lg-12 col-md-6" [formGroup]="sendReceiveForm" floatLabel="never"
                                        appearance="outline">
                                        <input class="text-input" matInput
                                            placeholder="{{'address_book.address_details.last_name' | translate}}" id="toLastName"
                                            name="toLastName" formControlName="toLastName" required>
                                            <mat-error *ngIf="hasErrors('toLastName')"><app-field-error-message errorMessage="{{getError('toLastName')}}"></app-field-error-message></mat-error>
                                    </mat-form-field>
                                </div>
                        </div>
                        <div class="to-option-container border-bottom">
                            <!-- <div class="col-lg-12 col-md-12" style="padding-left: 0px;"> -->
                        
                                <div class="row">
                        
                                    <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                                        floatLabel="never" appearance="outline">
                                        <input class="text-input" matInput
                                            placeholder="{{'address_book.address_details.company_name' | translate}}" id="toOptionalCompany"
                                            name="toOptionalCompany" formControlName="toOptionalCompany">
                                            <mat-error *ngIf="hasErrors('toOptionalCompany')"><app-field-error-message errorMessage="{{getError('toOptionalCompany')}}"></app-field-error-message></mat-error>
                                    </mat-form-field>
                                </div>
                        
                                <div class="row">
                                    <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                                        floatLabel="never" appearance="outline">
                                        <input class="text-input" matInput [matAutocomplete]="auto" 
                                            placeholder="{{'address_book.address_details.address_line_1' | translate}}" 
                                            id="toAddressLine1" name="toAddressLine1" formControlName="toAddressLine1" required
                                            (input)="onAddressInput()">
                                            
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAddressSelected($event)">
                                            <mat-option *ngFor="let address of filteredAddresses | async" [value]="address.addressLine1">
                                                {{ address.addressLine1 }}, {{ address.postcode }}, {{ address.townCity }}
                                            </mat-option>
                                        </mat-autocomplete>

                                        <mat-error *ngIf="hasErrors('toAddressLine1')">
                                            <app-field-error-message errorMessage="{{getError('toAddressLine1')}}"></app-field-error-message>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                        
                                <div class="row">
                                    <mat-form-field class="col-lg-4 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                                        <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="toPostcode" id="toPostcode" name="toPostcode" required
                                            placeholder="{{'address_book.address_details.postcode' | translate}}">
                                            <mat-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">{{postcode.postcode}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message></app-field-error-message></mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="col-lg-8 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                                        <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="toTownCity" id="toTownCity" name="toTownCity" required
                                        placeholder="{{'address_book.address_details.town_city' | translate}}">
                                            <mat-option *ngFor="let postcode of postcodes" [value]="postcode.city">{{postcode.city}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
                                    </mat-form-field>
                                </div>
                        
                                <div class="row">
                                    <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-12 col-md-6 form-field-with-error-spacing"
                                        floatLabel="never" appearance="outline">
                                        <input class="text-input" matInput formControlName="toContactNumber"
                                            placeholder="{{'address_book.address_details.contact_number' | translate}}">
                                            <mat-error *ngIf="hasErrors('toContactNumber')"><app-field-error-message errorMessage="{{getError('toContactNumber')}}"></app-field-error-message></mat-error>
                                    </mat-form-field>
                                </div>
                        
                                <div class="row">
                                    <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-12 col-md-6 form-field-with-error-spacing" floatLabel="never" appearance="outline">
                                        <input class="text-input" matInput formControlName="toEmail" placeholder="{{'address_book.address_details.email' | translate}}">
                                        <mat-error *ngIf="hasErrors('toEmail')"><app-field-error-message errorMessage="{{getError('toEmail')}}"></app-field-error-message></mat-error>
                                    </mat-form-field>
                                </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="specification-container">
            <div class="row full-width">
                <app-error-message style="width: 100%;" *ngIf="showNoSelectionError" errorMessage="{{'send_receive.send_receive.step_one.no_addresses_selected' | translate}}"></app-error-message>
            </div>

            <div class="row button-container full-width">
                <div class="col-lg-6 col-md-6 col-sm-4">
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 button-padding left-button">
                    <button class="tertiary-button button-width" mat-button (click)="clearAll()">
                        <div class="full-width">
                            <span class="button-text">{{'send_receive.send_receive.step_one.clear_all' | translate}}</span>
                            <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                        </div>
                    </button>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 button-width right-button">
                    <button mat-button class="primary-button button-width"
                        (click)="markRequiredFieldsAsTouchedAndProceed()">{{'send_receive.send_receive.step_one.proceed' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>