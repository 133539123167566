import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AddressService } from '../../../shared/services/address-service';
import { Address } from '../../../account/entities/address';
import { startWith, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserService } from '../../../account/services/user.service';
import { User } from '../../../account/entities/user';
import { Order } from '../../entities/Order';
import { ErrorHelper } from 'src/app/shared/helpers/error-helper';

@Component({
  selector: 'app-quick-order-addresses',
  templateUrl: './quick-order-addresses.component.html',
  styleUrls: ['./quick-order-addresses.component.scss']
})
export class QuickOrderAddressesComponent implements OnInit {
  @Input()
  sendReceiveForm: FormGroup

  @Input()
  specificationForm: FormGroup

  public addresses: Array<Address> = new Array<Address>();
  filteredAddresses!: Observable<Address[]>;

  public fromFilteredAddresses: Observable<Array<Address>>;

  public toFilteredAddresses: Observable<Array<Address>>;

  fromAddressControl = new FormControl();

  toAddressControl = new FormControl();

  public fromAddressSelected: Address;

  public toAddressSelected: Address;

  public currentUser: User;

  public postcodes: any;
  
  public showNoSelectionError: boolean;

  @Input()
  order: Order

  @Output()
  nextClicked: EventEmitter<boolean> = new EventEmitter();
  
  addressesFooter: any;
  
  @Input()
  psModule: any;
  observer: MutationObserver;

  constructor(private addressService: AddressService, 
              private userAccountService: UserService,
              private errorHelper: ErrorHelper,
              private elRef: ElementRef) { }

  ngOnInit(): void {
    this.getAddresses();
    this.getPostcodes();

    this.userAccountService.getUserDetails().subscribe(x => {
      this.currentUser = x;
    })

    this.filteredAddresses = this.sendReceiveForm.get('toAddressLine1')!.valueChanges.pipe(
      startWith(''),
      map(value => this.filterAddresses(value || ''))
  );

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['psModule']){
      this.addressesFooter = this.psModule.AddressesFooter;
    }
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  private _filter(value: string): Array<Address> {
    const filterValue = value.toLowerCase();

    return this.addresses.filter(option => `${option.firstName} ${option.lastName}, ${option.postcode}`.toLowerCase().includes(filterValue));
  }

  displayFn(address: Address): string {
    return address && `${address.firstName} ${address.lastName}, ${address.postcode}` ? `${address.firstName} ${address.lastName}, ${address.postcode}` : '';
  }

  public addressFieldClick() {
    this.addressService.getAll().subscribe((x) => {
      this.addresses = x;

      this.fromFilteredAddresses = this.fromAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
      )

    this.toFilteredAddresses = this.toAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice())
      );
    });
  }

  public getAddresses() {
    this.addressService.getParcelLockers().subscribe((x) => {
      this.addresses = x
    });
  }

  public getPostcodes() {
    this.addressService.getPostcodes().subscribe((x) => {
      this.postcodes = x
    });
  }

  public setFromSelectedAddress(address) {
    this.fromAddressSelected = address;
  }

  public setToSelectedAddress(address) {
    this.toAddressSelected = address;
  }

  public setToAddressFields() {
    if (!!this.toAddressSelected) {
      var selectedPostcode = this.postcodes.find(pc => pc.postcode == this.toAddressSelected.postcode || pc.city == this.toAddressSelected.townCity)
      if(!!selectedPostcode)
      {
        this.sendReceiveForm.get("toTownCity").setValue(selectedPostcode.city);
        this.sendReceiveForm.get("toPostcode").setValue(selectedPostcode.postcode);
      }
      this.sendReceiveForm.get("toTitle").setValue(this.toAddressSelected.title);
      this.sendReceiveForm.get("toFirstName").setValue(this.toAddressSelected.firstName);
      this.sendReceiveForm.get("toLastName").setValue(this.toAddressSelected.lastName);
      this.sendReceiveForm.get("toOptionalCompany").setValue(this.toAddressSelected.companyName);
      this.sendReceiveForm.get("toAddressLine1").setValue(this.toAddressSelected.addressLine1);
      this.sendReceiveForm.get("toAddressLine2").setValue(this.toAddressSelected.addressLine2);
      this.sendReceiveForm.get("toCountry").setValue(this.toAddressSelected.country);
      this.sendReceiveForm.get("toContactNumber").setValue(this.toAddressSelected.contactNumber);
      this.sendReceiveForm.get("toEmail").setValue(this.toAddressSelected.contactEmail);
      this.sendReceiveForm.get("toSpecialInstructions").setValue(this.toAddressSelected.specialInstructions);
    }
  }
  
  public hasErrors(field){
    var errors = this.sendReceiveForm.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.sendReceiveForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  
  public markRequiredFieldsAsTouchedAndProceed() {
    this.sendReceiveForm.markAllAsTouched();
    if (this.sendReceiveForm.invalid) {
      return;
    }
    this.nextClicked.emit(true);
  }

  public clearAll() {
    this.sendReceiveForm.reset();
  }

  public setSelectedPostcodeFO(event)
  {
    var sourceControlName = event.source.ngControl.name;
    if(sourceControlName == "toTownCity"){
      var selectedPostcode = this.postcodes.find(pc => pc.city == event.value)
      this.sendReceiveForm.get("toPostcode").setValue(selectedPostcode.postcode);
    }
    else if(sourceControlName == "toPostcode"){
      var selectedPostcode = this.postcodes.find(pc => pc.postcode == event.value)
      if(!!selectedPostcode)
      {
        this.sendReceiveForm.get("toTownCity").setValue(selectedPostcode.city);
      }
    }
  }

  filterAddresses(value: string): Address[] {
    const filterValue = value.toLowerCase();
    return this.addresses.filter(address =>
        address.addressLine1.toLowerCase().includes(filterValue)
    );
}

onAddressSelected(event: any) {
    const selectedAddress = this.addresses.find(addr => addr.addressLine1 === event.option.value);
    if (selectedAddress) {
        this.sendReceiveForm.patchValue({
            toAddressLine1: selectedAddress.addressLine1,
            toAddressLine2: selectedAddress.addressLine2,
            toTownCity: selectedAddress.townCity,
            toPostcode: selectedAddress.postcode,
            toCountryCode: selectedAddress.countryCode
        });
    }
}

onAddressInput() {
    this.filteredAddresses = of(this.filterAddresses(this.sendReceiveForm.get('toAddressLine1')!.value));
}
}