<div class="container">
    <div class="title-container">
        <img class="shopping-cart-icon" src="/assets/icons/shopping-list/shopping_cart_blue.png">
        <h1 class="page-title">{{'bera.edit_list.title' | translate}}</h1>
    </div>
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-xl-2 col-lg-4 col-md-4">
            <button class="primary-button full-width shopping-list-button" routerLink="../../offers-products">
                < {{'bera.edit_lists.products_offers' | translate}}</button>
        </div>
        <div *ngIf="isLoggedIn()" class="col-xl-5 col-lg-8 col-md-8">
            <mat-form-field class="shopping-list-dropdown full-width" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering placeholder="{{'bera.edit_list.active_list_name' | translate}}" panelClass="select-panel"
                    (selectionChange)="updateActiveList($event)" [(ngModel)]="activeList">
                    <mat-option *ngFor="let shoppingList of shoppingLists"
                        [value]="shoppingList">{{shoppingList.shoppingListName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="!isLoggedIn()" class="col-xl-5 col-lg-8 col-md-8">
            <mat-form-field class="shopping-list-dropdown full-width" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering placeholder="{{'bera.edit_list.active_list_name' | translate}}" panelClass="select-panel"
                    (selectionChange)="updateActiveList($event)" [(ngModel)]="loggedOutList">
                    <mat-option *ngFor="let shoppingList of allLoggedOutLists.shoppingLists"
                        [value]="shoppingList">{{shoppingList.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="isLoggedIn()" class="col-xl-5 col-lg-12">
            <div class="row">
                <div class="col-xl-8 col-lg-4 col-md-4">
                    <button class="secondary-button manual-item-button full-width shopping-list-button" (click)="addManualItem()"><img
                            class="add-list-icon" src="/assets/icons/shopping-list/Add.png">{{'bera.edit_list.add_manual_item' | translate}}</button>
                </div>
                <div class="col-xl-4 col-lg-2 col-md-3">
                    <button class="primary-button full-width shopping-list-button" (click)="createShareLink()"><img class="share-icon"
                            src="/assets/icons/shopping-list/Share.png">{{'bera.edit_list.share' | translate}}</button>
                </div>
            </div>
        </div>
        <div *ngIf="!isLoggedIn()" class="col-xl-5 col-lg-12">
            <div class="row">
                <div class="col-xl-8 col-lg-4 col-md-4">
                    <button class="secondary-button manual-item-button full-width shopping-list-button" (click)="addManualItem()"><img
                            class="add-list-icon" src="/assets/icons/shopping-list/Add.png">{{'bera.edit_list.add_manual_item' | translate}}</button>
                </div>
                <div class="col-xl-4 col-lg-2 col-md-3">
                    <button class="primary-button full-width shopping-list-button shareTooltip" disabled><img class="share-icon"
                            src="/assets/icons/shopping-list/Share.png">{{'bera.edit_list.share' | translate}}
                        <a tabindex="0"></a><span>{{'bera.edit_list.log_in_to_share' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="groupedProductsByRetailer && !isLoggedIn()" class="grey-background-container">
        <div class="retailer-container" *ngFor="let retailer of groupedProductsByRetailer">
            <div class="retailer-title-container">
                <span *ngIf="retailer[0] === ''" class="retailer-title">{{'bera.edit_list.manual_items' | translate}}</span>
                <span *ngIf="retailer[0] !== ''" class="retailer-title">{{retailer[0]}}</span>
                <!-- <a href="" class="retailer-link">View retailer details ></a> -->
            </div>
            <div class="product-container" *ngFor="let retailerProduct of retailer[1]">
                <div class="row">
                    <div class="col-lg-9 d-flex">
                        <div class="product-image-container">
                            <img class="product-img" [style.width.px]="retailerProduct.width" [style.height.px]="retailerProduct.height" src={{retailerProduct.imageUrl}}/>
                        </div>
                        <div class="product-desc-container">
                            <h3>{{retailerProduct.productName}}</h3>
                            <br *ngIf="!!retailerProduct.price">
                            <span *ngIf="!!retailerProduct.price"><span class="detail-blue">{{'bera.brochures.price' | translate}} </span><span class="detail-text">Kr. {{retailerProduct.price}}</span></span>
                            <br *ngIf="!!retailerProduct.offerUntilDate">
                            <span *ngIf="!!retailerProduct.offerUntilDate"><span class="detail-blue">{{'bera.shared_list.offer_details_until' | translate}} </span><span class="detail-text">{{retailerProduct.offerUntilDate | date:'dd.MM.yy'}}</span></span>
                        </div>
                    </div>
                    <div class="col-lg-3 product-desc-secondary-container">
                        <div>
                            <button class="remove-button" (click)="removeProduct(retailerProduct)"><img class="remove-icon" src="/assets/icons/shopping-list/remove.png">{{'bera.edit_lists.delete' | translate}}</button>
                            <div class="d-flex align-center" style="margin-top: 20px;">
                                <mat-checkbox class="bought-checkbox"
                                [checked]="retailerProduct.isBought"
                                (change)="toggleProductBought(retailerProduct)">
                                </mat-checkbox>
                                <span class="bought-text">{{'bera.edit_list.bought' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="groupedProductsByRetailer && isLoggedIn()" class="grey-background-container">
        <div class="retailer-container" *ngFor="let retailer of groupedProductsByRetailer">
            <div class="retailer-title-container">
                <span *ngIf="retailer[0] === ''" class="retailer-title">{{'bera.edit_list.manual_items' | translate}}</span>
                <span *ngIf="retailer[0] !== ''" class="retailer-title">{{retailer[0]}}</span>
                <!-- <a href="" class="retailer-link">View retailer details ></a> -->
            </div>
            <div class="product-container" *ngFor="let retailerProduct of retailer[1]">
                <div class="row">
                    <div class="col-lg-9 d-flex">
                        <div class="product-image-container">
                            <img class="product-img" [style.width.px]="retailerProduct.width" [style.height.px]="retailerProduct.height" src={{retailerProduct.imageUrl}}/>
                        </div>
                        <div class="product-desc-container">
                            <h3>{{retailerProduct.productItemName}}</h3>
                            <br *ngIf="!!retailerProduct.price">
                            <span *ngIf="!!retailerProduct.price"><span class="detail-blue">{{'bera.brochures.price' | translate}} </span><span class="detail-text">Kr. {{retailerProduct.price}}</span></span>
                            <br *ngIf="!!retailerProduct.offerUntilDate">
                            <span *ngIf="!!retailerProduct.offerUntilDate"><span class="detail-blue">{{'bera.shared_list.offer_details_until' | translate}} </span><span class="detail-text">{{retailerProduct.offerUntilDate | date:'dd.MM.yy'}}</span></span>
                        </div>
                    </div>
                    <div class="col-lg-3 product-desc-secondary-container">
                        <div>
                            <button class="remove-button" (click)="removeProduct(retailerProduct)"><img class="remove-icon" src="/assets/icons/shopping-list/remove.png">{{'bera.edit_lists.delete' | translate}}</button>
                            <div class="d-flex align-center" style="margin-top: 20px;">
                                <mat-checkbox class="bought-checkbox"
                                [checked]="retailerProduct.isBought"
                                (change)="toggleProductBought(retailerProduct)">
                                </mat-checkbox>
                                <span class="bought-text">{{'bera.edit_list.bought' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="retailer-container" *ngIf="isLoggedIn() && boughtItems && boughtItems.length > 0">
            <div class="retailer-title-container">
                <span class="retailer-title">{{'bera.edit_list.bought_items' | translate}}</span>
            </div>
            <div class="product-container" *ngFor="let retailerProduct of boughtItems">
                <div class="row">
                    <div class="col-lg-9 d-flex">
                        <div class="product-image-container">
                            <img class="product-img" [style.width.px]="retailerProduct.width" [style.height.px]="retailerProduct.height" src={{retailerProduct.imageUrl}}/>
                        </div>
                        <div class="product-desc-container">
                            <h3>{{retailerProduct.productItemName}}</h3>
                            <br *ngIf="!!retailerProduct.price">
                            <span *ngIf="!!retailerProduct.price"><span class="detail-blue">{{'bera.brochures.price' | translate}} </span><span class="detail-text">Kr. {{retailerProduct.price}}</span></span>
                            <br *ngIf="!!retailerProduct.offerUntilDate">
                            <span *ngIf="!!retailerProduct.offerUntilDate"><span class="detail-blue">{{'bera.shared_list.offer_details_until' | translate}} </span><span class="detail-text">{{retailerProduct.offerUntilDate | date:'dd.MM.yy'}}</span></span>
                        </div>
                    </div>
                    <div class="col-lg-3 product-desc-secondary-container">
                        <div>
                            <button class="remove-button" (click)="removeProduct(retailerProduct)"><img class="remove-icon" src="/assets/icons/shopping-list/remove.png">{{'bera.edit_lists.delete' | translate}}</button>
                            <div class="d-flex align-center" style="margin-top: 20px;">
                                <mat-checkbox class="bought-checkbox"
                                [checked]="retailerProduct.isBought"
                                (change)="toggleProductBought(retailerProduct)">
                                </mat-checkbox>
                                <span class="bought-text">{{'bera.edit_list.bought' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>