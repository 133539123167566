<div *ngIf="shoppingList && !isLoading" class="container">
    <div class="title-container">
        <div class="row" style="width: 100%; align-items: center; display: flex;">
            <div class="col-auto" style="padding-right: 0px;">
                <img class="shopping-cart-icon" src="/assets/icons/shopping-list/shopping_cart_blue.png">
            </div>
            <div class="col">
                <h1 class="page-title">{{'bera.shared_list.title' | translate}} - {{shoppingList.shoppingListName}}</h1>
            </div>
            <div class="col-auto">
                <button class="primary-button full-width shopping-list-button" routerLink="../../../shopping-services/offers-products">
                < {{'bera.edit_lists.products_offers' | translate}}</button>
            </div>
        </div>
    </div>
    <!-- <div class="row" style="margin-bottom: 20px;">
        <div class="col-xl-2 col-lg-4 col-md-4">
            <button class="primary-button full-width shopping-list-button" routerLink="../../offers-products">
                < Products & offers</button>
        </div>
        <div class="col-xl-5 col-lg-8 col-md-8">
            <mat-form-field class="shopping-list-dropdown full-width" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering placeholder="Active list name" panelClass="select-panel"
                    (selectionChange)="updateActiveList($event)" [(ngModel)]="activeList">
                    <mat-option *ngFor="let shoppingList of shoppingLists"
                        [value]="shoppingList">{{shoppingList.shoppingListName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xl-5 col-lg-12">
            <div class="row">
                <div class="col-xl-8 col-lg-4 col-md-4">
                    <button class="secondary-button manual-item-button full-width shopping-list-button" (click)="addManualItem()"><img
                            class="add-list-icon" src="/assets/icons/shopping-list/Add.png">Add manual item to
                        list</button>
                </div>
                <div class="col-xl-4 col-lg-2 col-md-3">
                    <button class="primary-button full-width shopping-list-button" (click)="createShareLink()"><img class="share-icon"
                            src="/assets/icons/shopping-list/Share.png">Share</button>
                </div>
            </div>
        </div>
    </div> -->
    <div *ngIf="groupedProductsByRetailer" class="grey-background-container">
        <div class="retailer-container" *ngFor="let retailer of groupedProductsByRetailer">
            <div class="retailer-title-container">
                <span *ngIf="retailer[0] === '' || retailer[0] === 'null'" class="retailer-title">{{'bera.shared_list.manual_items' | translate}}</span>
                <span *ngIf="retailer[0] !== '' && retailer[0] !== 'null'" class="retailer-title">{{retailer[0]}}</span>
                <!-- <a href="" class="retailer-link">View retailer details ></a> -->
            </div>
            <div class="product-container" *ngFor="let retailerProduct of retailer[1]">
                <div class="row">
                    <div class="col-lg-12 d-flex">
                        <div class="product-image-container">
                            <img class="product-img" [style.width.px]="retailerProduct.width" [style.height.px]="retailerProduct.height" src={{retailerProduct.imageUrl}}/>
                        </div>
                        <div class="product-desc-container">
                            <h3 *ngIf="retailerProduct.productName === '' || !retailerProduct.productName">{{retailerProduct.productItemName}}</h3>
                            <h3 *ngIf="retailerProduct.productName !== '' && retailerProduct.productName">{{retailerProduct.productName}}</h3>
                            <br *ngIf="!!retailerProduct.price">
                            <span *ngIf="!!retailerProduct.price"><span class="detail-blue">{{'bera.brochures.price' | translate}} </span><span class="detail-text">Kr. {{retailerProduct.price}}</span></span>
                            <br *ngIf="!!retailerProduct.offerUntilDate">
                            <span *ngIf="!!retailerProduct.offerUntilDate"><span class="detail-blue">{{'bera.shared_list.offer_details_until' | translate}} </span><span class="detail-text">{{retailerProduct.offerUntilDate | date:'dd.MM.yy'}}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>